import { addServices, deleteService, getServices, updateService } from "../../../services/service.service";

export const SERVICE_ADD = "SERVICE_ADD";
export const SERVICE_ADD_SUCCESS = "SERVICE_ADD_SUCCESS";
export const SERVICE_ADD_FAIL = "SERVICE_ADD_FAIL";

export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAIL = "GET_ALL_SERVICES_FAIL";

export const UPDATE_SERVICE_BY_ID = "UPDATE_SERVICE_BY_ID";
export const UPDATE_SERVICE_BY_ID_SUCCESS = "UPDATE_SERVICE_BY_ID_SUCCESS";
export const UPDATE_SERVICE_BY_ID_FAIL = "UPDATE_SERVICE_BY_ID_FAIL";

export const SET_SERVICE_OBJ = "SET_SERVICE_OBJ";
export const SET_SERVICE_OBJ_SUCCESS = "SET_SERVICE_OBJ_SUCCESS";
export const SET_SERVICE_OBJ_FAIL = "SET_SERVICE_OBJ_FAIL";

export const GET_SERVICE_BY_ID = "GET_SERVICE_BY_ID";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";
export const GET_SERVICE_BY_ID_FAIL = "GET_SERVICE_BY_ID_FAIL";

export const DELETE_SERVICE_BY_ID = "DELETE_SERVICE_BY_ID";
export const DELETE_SERVICE_BY_ID_SUCCESS = "DELETE_SERVICE_BY_ID_SUCCESS";
export const DELETE_SERVICE_BY_ID_FAIL = "DELETE_SERVICE_BY_ID_FAIL";

export const SERVICEADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SERVICE_ADD });
    let { data: response } = await addServices(formData);
    if (response) {
      dispatch(SERVICEGET())
      dispatch({
        type: SERVICE_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SERVICE_ADD_FAIL, payload: err });
  }
};

export const SERVICEGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SERVICES });
    let { data: response } = await getServices(formData);
    if (response) {

      dispatch({
        type: GET_ALL_SERVICES_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SERVICE_ADD_FAIL, payload: err });
  }
};

export const SETSERVICEOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SERVICE_OBJ });
    if (formData) {
      dispatch({
        type: SET_SERVICE_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SERVICE_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SERVICE_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const SERVICEUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SERVICE_BY_ID });
    let { data: response } = await updateService(formData, id);
    if (response) {
      console.log(response);
      dispatch(SERVICEGET())

      dispatch({
        type: UPDATE_SERVICE_BY_ID_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_SERVICE_BY_ID_FAIL, payload: err });
  }
};

export const SERVICEDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SERVICE_BY_ID });
    let { data: response } = await deleteService(id);
    if (response) {
      console.log(response, "response");
      dispatch(SERVICEGET());
      dispatch({
        type: DELETE_SERVICE_BY_ID_SUCCESS,
        payload: response
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_SERVICE_BY_ID_FAIL, payload: err });
  }
};