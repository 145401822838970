import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate, SetCATEGORYObj } from "../../../redux/actions/Category/Category.actions";
import { SETSTATEOBJ, STATEADD, STATEGET, STATEUPDATE } from "../../../redux/actions/State/States.actions";
import { CITYGET } from "../../../redux/actions/City/City.actions";
import { AREAADD, AREAUPDATE, SETAREAOBJ } from "../../../redux/actions/Area/Area.actions";
import { toastError } from "../../Utility/ToastUtils";
function AddArea({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const [stateArr, setStateArr] = useState([]);
    const [stateId, setStateId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const [cityArr, setCityArr] = useState([]);
    const [cityId, setCityId] = useState("");
    const [citiesObj, setCitiesObj] = useState({});
    const statesObj = useSelector((state) => state.states.statesObj);
    const cityObj = useSelector((state) => state.city.citiesObj);
    const statesArr = useSelector((state) => state.states.states);
    const citiesArr = useSelector((state) => state.city.cities);

    const areaObj = useSelector((state) => state.area.areasObj);
    const handleAddCategory = () => {

        if(name == "") {
            toastError("Please add name");
            return 0
        }
        if(stateId == "") {
            toastError("Please add state");
            return 0
        }
        if(cityId == "") {
            toastError("Please add city");
            return 0
        }
        let obj = {
            name,
            status,
            stateId,
            cityId
        };
        console.log(obj, "category obj");

        if (areaObj?._id) {
            dispatch(AREAUPDATE(areaObj._id, obj));
            dispatch(SETAREAOBJ(null))
        } else {
            dispatch(AREAADD(obj));
        }
    };

    useEffect(() => {
        if (areaObj) {
            setName(areaObj?.name);
            setStatus(areaObj?.status);
        }
        if (stateArr && stateArr.length > 0) {
            console.log(...stateArr.filter(el => el._id == areaObj?.stateId), "stateArr.filter(el => el._id == cityObj.stateId)")
            setStateObj(...stateArr.filter(el => el._id == areaObj?.stateId).map(el => { return { ...el, label: el.name, value: el._id } }))
        }
        if (cityArr && cityArr.length > 0) {
            console.log(...cityArr.filter(el => el._id == areaObj?.cityId), "stateArr.filter(el => el._id == cityObj.stateId)")
            setStateObj(...cityArr.filter(el => el._id == areaObj?.cityId).map(el => { return { ...el, label: el.name, value: el._id } }))
        }
        // return () => {
        //     dispatch(SETSTATEOBJ(null));
        // };
    }, [areaObj, stateArr, cityArr]);




    useEffect(() => {
        dispatch(STATEGET());
    }, [])

    useEffect(() => {
        if (statesArr) {
            setStateArr(statesArr)
        }
        if (citiesArr) {
            setCityArr(citiesArr)
        }
    }, [statesArr, citiesArr]);



    // useEffect(() => {
    //     dispatch(CATEGORYGet());
    // }, []);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-12">
                    <label>
                        State <span className="red">*</span>
                    </label>
                    <Select
                        options={stateArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                        placeholder="Select from options"
                        defaultInputValue={stateId} value={stateObj}
                        onChange={(e) => {
                            console.log(e, "asd")
                            setStateId(e.value);
                            setStateObj(e);
                            dispatch(CITYGET(`stateId=${e.value}`));
                        }}
                    />
                </div>
                <div className="col-12 col-md-12">
                    <label>
                        City <span className="red">*</span>
                    </label>
                    <Select
                        options={cityArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                        placeholder="Select from options"
                        defaultInputValue={cityId} value={citiesObj}
                        onChange={(e) => {
                            console.log(e, "asd")
                            setCityId(e.value);
                            setCitiesObj(e);
                        }}
                    />
                </div>
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddArea;
