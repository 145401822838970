import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/adminProductOrder";

export const add = async (formData) => {
  // console.log(axiosApiInstance)
  return await axiosApiInstance.post(serverUrl + "/", formData);
};

export const get = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAllProductStock?${query}`);
};

export const deleteById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
