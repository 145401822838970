import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  ADMINPRODUCTORDERSTOCKAdd,
  ADMINPRODUCTORDERSTOCKGet,
  SetADMINPRODUCTORDERSTOCKObj,
  ADMINPRODUCTORDERSTOCKUpdate,
  ADMINPRODUCTORDERSTOCKDelete,
} from "../../redux/actions/AdminProductOrderStocks/AdminProductOrderStocks.actions";
import { generateFilePath } from "../Utility/utils";

function StocksManagement() {
  let dispatch = useDispatch();
  const stockArray = useSelector(
    (state) => state.AdminProductOrderStock.adminProductOrderStock
  );
  console.log(stockArray, "stockArrayArray23");

  const handleGet = () => {
    dispatch(ADMINPRODUCTORDERSTOCKGet());
  };
  useEffect(() => {
    handleGet();
  }, []);

  const handleBlogDelete = (id) => {
    // console.log(id , "des");
    dispatch(ADMINPRODUCTORDERSTOCKDelete(id));
  };
  const handleBlogEdit = (row) => {
    // dispatch(SetBlogObj(row));
  };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "product name",
      selector: (row) => row.productName,
      // width: "20%",
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img
          // height="84px"
          // width="56px"
          alt={row?.productObj?.imageArr[0].image}
          src={generateFilePath(row?.productObj?.imageArr[0].image)}
        />
      ),
    },
    {
      name: "sku",
      grow: 0,
      selector: (row) => row?.productObj?.sku,
      // width: "20%",
    },
    {
      name: "status",
      grow: 0,
      selector: (row) => row?.productObj?.status,
      // width: "20%",
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          remove
          edit
          detailpath="/Blog/View-Post"
          Uniquekey={row.id}
          deletePath="/ProductStocks-Order"
          onDeleteClick={() => {
            handleBlogDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleBlogEdit(row);
          }}
          editPath="/ProductStocks-Order/Add"
        />
      ),
      // width: "25%",
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Stock List</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD PRODUCT"
                    path="/ProductStocks-Order/Add  "
                  />
                  <SearchBox extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  data={stockArray && stockArray.length > 0 ? stockArray : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default StocksManagement;
