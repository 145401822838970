import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import { INTERNALORDERGet, INTERNALORDER_ROLE_Get, SetINTERNALORDERObj } from "../../redux/actions/InternalOrder/InternalOrder.actions";
import { DashboardTable } from "../Utility/DashboardBox";
import { getAllInternalOrderProductByUser } from "../../services/InternalOrder.services";
import { toastError } from "../Utility/ToastUtils";
import {  INTERNAL_ORDER_ORDER_FOR } from "../Utility/constants";
import { rolesObj } from "../../utils/roles";

export default function AllStoreInternalOrders() {
    // ==============================================================================================================
    const dispatch = useDispatch();
    const [displayInternalOrderArr, setDisplayInternalOrderArr] = useState([]);
    const [query, setQuery] = useState("");
    const [internalOrderMainArr, setInternalOrderMainArr] = useState([]);
    const role = useSelector((state) => state?.auth?.role);
    const internalOrderArr = useSelector((state) => state.internalOrder.internalRoleOrder);
    const user = useSelector((state) => state?.auth?.user);

    const userRole = role == 'ADMIN'? 'WareHouse':'Store'

    const handleCategoryEdit = (row) => {
        dispatch(SetINTERNALORDERObj(row));
    };


    const handleGet = () => {

        let query = '';
    
        query +=`orderFor=${INTERNAL_ORDER_ORDER_FOR.STORE_STOCK_IN}`;
        query+= `&orderedFromId=${user?._id}`
      
    //   if(role == rolesObj.STORE){
    //     query +=`orderFor=${INTERNAL_ORDER_ORDER_FOR.STORE_STOCK_IN}`;
    //     query+= `&orderedToId=${user?._id}`
    //   }
   
      console.log(query)
        dispatch(INTERNALORDER_ROLE_Get(query));
    };

    useEffect(() => {
        handleGet()
    }, [])

    useEffect(() => {
        if (internalOrderArr?.length) {
            console.log(internalOrderArr, "internalOrderArr")
            setInternalOrderMainArr(internalOrderArr)
            setDisplayInternalOrderArr(internalOrderArr)
        } else {
            setInternalOrderMainArr([])
            setDisplayInternalOrderArr([])
        }
    }, [internalOrderArr])

    const category_columns = [
        {
            name: "ID",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Seller Name",
            selector: (row) => row?.sellerDetails?.name,
        },
        {
            name: "Products Count",
            selector: (row) => row?.totalProductCount,
        },
        {
            name: "Ordered On",
            selector: (row) => new Date(row?.createdAt).toDateString(),
        },
        {
            name: "Status",
            selector: (row) => row.orderStatus.currentStatus,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => <CustomButton key={row._id} isLink iconName="fa-solid fa-eye" btnName="View Order" path={`/internal-order-${userRole}/View-internal-order/${row._id}`} />
        },
    ];

    // ==============================================================================================================

    const handleFilterByQuery = (e, requiredParametersArr) => {
        let tempArr = displayInternalOrderArr.filter(el => {
            for (const ele of requiredParametersArr) {
                console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el")
                if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
                    // console.log("true")
                    return true;
                }
                else {
                    return false;
                }
            }
        })
        setQuery(e)
        setInternalOrderMainArr([...tempArr])
        console.log([...tempArr], "...tempArr")
    }


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1 m-0"> Store Order List</h5>
                                <div className="d-flex gap-3 align-items-center">
                                    {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                                    <SearchBox setQuery={(e) => { handleFilterByQuery(e, ["name"]); }} query={query} extraClass="bg-white" />
                                </div>
                            </div>
                            <DashboardTable>
                                <DataTable columns={category_columns} data={internalOrderMainArr && internalOrderMainArr.length > 0 ? internalOrderMainArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
