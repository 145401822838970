import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { generalModelStatuses } from "../Utility/constants";
import { rolesObj } from "../../utils/roles";

function SideBar({ style }) {
  let role = useSelector((state) => state.auth.role);
  let user = useSelector((state) => state.auth.user);

  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "dashboard",
      roleArr: ["ADMIN", "SUBADMIN"],
      path: "/",
      icon: "ion-grid",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Location",
      roleArr: ["ADMIN", "SUBADMIN"],
      path: "/",
      icon: "fa-solid fa-location-dot",
      children: [
        {
          name: "State",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Location/View-States",
          active: false,
        },
        {
          name: "City",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Location/View-City",
          active: false,
        },
        {
          name: "Area",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Location/View-Area",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "products",
      path: "/Dashboard",
      icon: "fa-brands fa-product-hunt",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [
        {
          name: "Service",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Product/Service",
          active: false,
        },
        {
          name: "Category",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Product/Category",
          active: false,
        },
        {
          name: "Brand",
          path: "/Product/Brand",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Attribute",
          path: "/Product/Attribute",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Add New Product",
          path: "/Product/AddProduct",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        // {
        //   name: "Bulk Product Upload",
        //   path: "/Product/Bulk-Product-Upload",
        //   roleArr: ["ADMIN", "SUBADMIN"],
        //   active: false,
        // },
        {
          name: "Product List",
          path: "/Product/Product-List",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },

      ],
    },
      // {
      //   isrotated: true,
      //   active: true,
      //   name: "Supplier",
      //   roleArr: ["ADMIN", "SUBADMIN"],
      //   path: "/Supplier/View-Supplier",
      //   icon: "ion-home",
      //   children: [],
      // },
    {
      isrotated: true,
      active: true,
      name: "warehouse",
      roleArr: ["ADMIN", "SUBADMIN"],
      path: "/Warehouse/View-Warehouse",
      icon: "ion-home",
      children: [],
    },

    {
      isrotated: true,
      active: true,
      name: "store",
      roleArr: ["ADMIN", "SUBADMIN"],
      path: "/Warehouse/View-Store",
      icon: "ion-home",
      children: [],
    },
    {
      isrotated: true,
      active: true,
      name: "Vendor",
      roleArr: ["ADMIN", "SUBADMIN"],
      path: "/Warehouse/View-Vendor",
      icon: "ion-home",
      children: [],
    },

    {
      isrotated: false,
      active: false,
      name: "Users",
      path: "/Dashboard",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [
        {
          name: "All Users",
          path: "/User-list",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Customer KYC",
          path: "/Customer/KYC",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Internal Orders",
      path: "/internal-order",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
      children: [
        {
          name: "Buy Internal Orders",
          path: "internal-order/BuyInternalOrders",
          roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
          active: false,
        },
        {
          name: "Sell Internal Orders",
          path: "internal-order/SellInternalOrder",
          roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
          active: false,
        },
        {
          name: "All WareHouse Orders",
          path: "internal-order/AllWareHouseOrder",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "All Store Orders",
          path: "internal-order/AllStoreOrder",
          roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE"],
          active: false,
        }
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Delivery",
      roleArr: ["ADMIN", "SUBADMIN", "DELIVERYTEAM"],
      path: "/Delivery",
      icon: "fa-solid fa-user",
      children: [
        {
          name: "User",
          path: "Delivery/User",
          roleArr: ["ADMIN", "SUBADMIN", "DELIVERYTEAM"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Frontend CMS",
      path: "/Dashboard",
      roleArr: ["ADMIN", "SUBADMIN"],
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Home Page",
          path: "/HomePage",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Banners",
          path: "/Banners",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Offer Banner",
          path: "/Offer",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Sales",
          path: "/Sales",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Coupon",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          path: "/Coupon/View-Coupon",
          children: [],
        },



      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Product stock order",
      path: "/Dashboard",
      roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Product Stock",
          path: "/Product/Product-Stock",
          roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
          active: false,
        }, ,
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Orders",
      path: "/order",
      icon: "fa-solid fa-shopping-cart",
      roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
      children: [
        {
          name: "All Orders",
          path: "/Order/Total-Order",
          roleArr: ["ADMIN", "SUBADMIN", "WAREHOUSE", "STORE", "OFFLINESTORE"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Contact Request",
      path: "/Dashboard",
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Contact Mail",
          path: "/Contact-Mail",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Setup",
      path: "/",
      icon: "ion-settings",
      children: [
        {
          name: "Currency List",
          path: "/SetUp/Currency-List",
          active: false,
        },
        {
          name: "Location",
          path: "/SetUp/Location",
          active: false,
        },
        {
          name: "Tags",
          path: "/SetUp/Tags",
          active: false,
        },
      ],
    },

  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children && el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div id="sidebar">
      <div
        className="main-logo"
        style={style ? { padding: "26px 10px", height: "auto" } : {}}
      >
        {style ? (
          <img src={images?.favicon} alt="" />
        ) : (
          <img style={{ height: 70 }} src={images?.logo} alt="" />
        )}
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item &&
          sidebar_item?.map((item, i) => {
            if (
              typeof array === "undefined" &&
              item?.children &&
              item?.children?.length === 0 &&
              item?.roleArr.some(
                (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
              )
            ) {
              return (
                <li key={`sidebar_item_${i}`}>
                  <Link
                    to={item?.path}
                    className={item?.active ? "active" : ""}
                    onClick={() => isRotating(i)}
                  >
                    <i className={item?.icon}></i>
                    {!style && <p className="mb-0">{item?.name}</p>}
                  </Link>
                </li>
              );
            } else {
              if (
                item?.roleArr &&
                item?.roleArr?.length > 0 &&
                item?.roleArr.some(
                  (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
                )
              ) {

                if (role == rolesObj.ADMIN || user.isKyc == generalModelStatuses.APPROVED) {
                  return (
                    <li key={`sidebar_item_${i}`}>
                      <Link
                        to={`#sidebar_item_children_${i}`}
                        className={
                          item?.active || location === item?.path ? "active" : ""
                        }
                        data-bs-toggle="collapse"
                        aria-expanded={item?.active}
                        aria-controls={`sidebar_item_children_${i}`}
                        role="button"
                        onClick={() => isRotating(i)}
                      >
                        <i className={item.icon}></i>
                        {!style && (
                          <p className="mb-0">
                            {item?.name}
                            {item?.isrotated ? (
                              <i className="ion-arrow-up-b pe-3"></i>
                            ) : (
                              <i className="ion-arrow-down-b pe-3"></i>
                            )}
                          </p>
                        )}
                      </Link>
                      {!style && (
                        <ul
                          className="collapse"
                          id={`sidebar_item_children_${i}`}
                          data-bs-parent="#sidebarMenu"
                        >
                          {item?.children?.map((child, index) => {
                            if (
                              child?.roleArr &&
                              child?.roleArr?.length > 0 &&
                              child?.roleArr?.some(
                                (el) =>
                                  `${el}`?.toLowerCase() ==
                                  `${role}`?.toLowerCase()
                              )
                            ) {
                              return (
                                <li key={`${child?.name}_${index}`}>
                                  <Link
                                    to={child?.path}
                                    className={
                                      child?.active || location === child?.path
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => childActive(index)}
                                  >
                                    {child?.name}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      )}
                    </li>
                  );
                }

              }
            }
          })}
      </ul>
    </div>
  );
}

export default SideBar;
