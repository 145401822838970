import { addSupplier, deleteSupplier, getSupplier, updateSupplier } from "../../../services/Supplier.service";

export const SUPPLIER_ADD = "SUPPLIER_ADD";
export const SUPPLIER_ADD_SUCCESS = "SUPPLIER_ADD_SUCCESS";
export const SUPPLIER_ADD_FAIL = "SUPPLIER_ADD_FAIL";

export const GET_ALL_SUPPLIER = "GET_ALL_SUPPLIER";
export const GET_ALL_SUPPLIER_SUCCESS = "GET_ALL_SUPPLIER_SUCCESS";
export const GET_ALL_SUPPLIER_FAIL = "GET_ALL_SUPPLIER_FAIL";

export const UPDATE_SUPPLIER_BY_ID = "UPDATE_SUPPLIER_BY_ID";
export const UPDATE_SUPPLIER_BY_ID_SUCCESS = "UPDATE_SUPPLIER_BY_ID_SUCCESS";
export const UPDATE_SUPPLIER_BY_ID_FAIL = "UPDATE_SUPPLIER_BY_ID_FAIL";

export const SET_SUPPLIER_OBJ = "SET_SUPPLIER_OBJ";
export const SET_SUPPLIER_OBJ_SUCCESS = "SET_SUPPLIER_OBJ_SUCCESS";
export const SET_SUPPLIER_OBJ_FAIL = "SET_SUPPLIER_OBJ_FAIL";

export const GET_SUPPLIER_BY_ID = "GET_SUPPLIER_BY_ID";
export const GET_SUPPLIER_BY_ID_SUCCESS = "GET_SUPPLIER_BY_ID_SUCCESS";
export const GET_SUPPLIER_BY_ID_FAIL = "GET_SUPPLIER_BY_ID_FAIL";

export const DELETE_SUPPLIER_BY_ID = "DELETE_SUPPLIER_BY_ID";
export const DELETE_SUPPLIER_BY_ID_SUCCESS = "DELETE_SUPPLIER_BY_ID_SUCCESS";
export const DELETE_SUPPLIER_BY_ID_FAIL = "DELETE_SUPPLIER_BY_ID_FAIL";

export const SUPPLIERADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUPPLIER_ADD });
    let { data: response } = await addSupplier(formData);
    if (response) {
      dispatch(SUPPLIERGET())
      dispatch({
        type: SUPPLIER_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SUPPLIER_ADD_FAIL, payload: err });
  }
};

export const SUPPLIERGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUPPLIER });
    let { data: response } = await getSupplier(formData);
    if (response) {

      dispatch({
        type: GET_ALL_SUPPLIER_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SUPPLIER_ADD_FAIL, payload: err });
  }
};

export const SETSUPPLIEROBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SUPPLIER_OBJ });
    if (formData) {
      dispatch({
        type: SET_SUPPLIER_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SUPPLIER_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SUPPLIER_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const SUPPLIERUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SUPPLIER_BY_ID });
    let { data: response } = await updateSupplier(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_SUPPLIER_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(SUPPLIERGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_SUPPLIER_BY_ID_FAIL, payload: err });
  }
};

export const SUPPLIERDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SUPPLIER_BY_ID });
    let { data: response } = await deleteSupplier(id);
    if (response) {
      console.log(response, "response");
      dispatch({
        type: DELETE_SUPPLIER_BY_ID_SUCCESS,
        payload: response
      });
      dispatch(SUPPLIERGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_SUPPLIER_BY_ID_FAIL, payload: err });
  }
};