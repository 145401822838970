import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleProductStockByRoleById } from "../../services/InternalOrder.services";
import { rolesObj } from "../../utils/roles";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { generalModelStatuses, INTERNAL_ORDER_ORDER_FOR } from "../Utility/constants";
import { useSelector, useDispatch } from "react-redux";
import { DashboardBox } from "../Utility/DashboardBox";

function SingleProductStock() {

    let {id,variantId} = useParams();
  const [selectedProductArr, setselectedProductArr] = useState([])
const handleValueChange = "";
const [productArr, setproductArr] = useState([]);
const role = useSelector((state) => state.auth.role);
const user = useSelector((state) => state.auth.user);
const handleGetproducts = async (id) => {
  try { 

 
   
     
    let currentStatus = generalModelStatuses.DELIVERED;

    let orderedToId = user?._id;
    let query = `orderedToId=${orderedToId}`;
    let productId = `${id}/${variantId}`
   let {data:res} = await getSingleProductStockByRoleById(productId,query);
   console.log(res,"productArr")

   if(res?.data){
    setselectedProductArr(res?.data);
   }
  } catch (error) {
    toastError(error)
  }
}


useEffect(() => {
  handleGetproducts(id,variantId);


}, [id])

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <h5 className="blue-1 mb-4">Product Stock Batch Wise</h5>
              <DashboardBox className="col-lg-12">
                <form action="#" className="form row">
                  
                  <div className="col-12 mb-4">
                   
                    <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">BatchId</th>
                                                    <th scope="col">Name</th>
                                                    {/* <th scope="col">Selling Price</th> */}
                                                    <th scope="col">Buying Price</th>
                                                    <th scope="col">Stock</th>
                                                    <th scope="col">Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedProductArr &&
                                                    selectedProductArr.length > 0 &&
                                                    selectedProductArr.map((el, index) => {
                                                        return (
                                                          
                                                            <tr key={index}>
                                                                <th scope="row">{el.batchId}</th>
                                                                <td>
                                                                {el?.productobj?.name}
                                                                </td>
                                                                {/* <td>
                                                                  {el.sellingPrice}
                                                                </td> */}
                                                                <td>
                                                                {el.buyingPrice}
                                                                </td>

                                                                <td>
                                                                   {el.quantity}
                                                                </td>
                                                              <td>
                                                                {new Date(el.createdAt).toDateString()}
                                                              </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                  </div>
                  
                </form>
              </DashboardBox>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SingleProductStock;
