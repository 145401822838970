import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import { newNewsletter } from "../../services/newsletter.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getById, updateKyc } from "../../services/users.service";
import { generalModelStatuses } from "../Utility/constants";
import { AUTH_SUCCESS } from "../../redux/actions/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { generateFilePath } from "../Utility/utils";

function KYC() {
  const [pan, setPan] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [panFile, setPanFile] = useState("");
  const [aadharFile, setAadharFile] = useState("");
  const [description, setDescription] = useState("");
  const [userObj, setuserObj] = useState("");
  let user = useSelector((state) => state.auth.user);
  let auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handlegetUser =async  () => {
      try {
      const { data: res } = await getById(user._id);

        if (res.data) {
            setuserObj(res.data)
            dispatch({
              type: AUTH_SUCCESS,
              payload: {
                ...auth,user: {...user ,isKyc:res.data.isKyc},
              },
            });
        }
      } catch (error) {
        console.log(error)  
  }

}

useEffect(() => {
  handlegetUser()
}, [])

  

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {

        if (pan == "" || pan.length !=10) {
            toastError("Please fill valid PAN No.")
            return
          }
        if (aadhar == "" || aadhar.length !=12) {
            toastError("Please fill valid Aadhar No.")
            return
          }

          if (aadharFile == "") {
            toastError("Please upload aadhar photo.")
            return
          }
          if (panFile == "") {
            toastError("Please upload pan photo.")
            return
          }
      let obj = {
        pan,
        aadhar,
        aadharFile,
        panFile,
        isKyc:generalModelStatuses.APPLIED
      };
      const { data: res } = await updateKyc(obj,user._id);
      if (res.message) {
        toastSuccess(res.message)
      }
    } catch (error) {
        toastError(error);
    }
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">KYC : {userObj?.isKyc}</h5>
          
                    <div className="row">
                    {
                     userObj &&  userObj.isKyc == generalModelStatuses.PENDING && (
                    <div className="col-12 col-md-8">
                      <DashboardBox>
                        <div className="row">
                          <h5 className="blue-1 mb-4">KYC Details</h5>
                          <div className="col-12 mb-3">
                            <label>
                              PAN No. <span className="red">*</span>
                            </label>
                            <input value={pan} onChange={(e) => setPan(e.target.value)} type="text" className="form-control" />
                          </div>
                          <div className="col-12 mb-3">
                            <label>
                               Aadhar No. <span className="red">*</span>
                            </label>
                            <input value={aadhar} onChange={(e) => setAadhar(e.target.value)} type="text" maxLength="12" className="form-control" />
                          </div>
                          <div className="col-12 mb-3">
                            <label>
                               Upload Pan Card<span className="red">*</span>
                            </label>
                            <FileUpload acceptImage={true} onFileChange={(value) => setPanFile(value)} />
      
                          </div>
                          <div className="col-12 mb-3">
                            <label>
                               Upload Aadhar Card<span className="red">*</span>
                            </label>
                            <FileUpload acceptImage={true} onFileChange={(value) => setAadharFile(value)} />
      
                          </div>
                          {/* <div className="col-12">
                            <label>
                              DESCRIPTION<span className="red">*</span>
                            </label>
                            <ReactQuill onChange={(e) => setDescription(e)} />
                          </div> */}
                             <div className="col-12 mt-4">
                          <CustomButton ClickEvent={(e) => handleSubmit(e)} isBtn iconName="fa-solid fa-check" btnName="Save" />
                        </div>
                        </div>

                     
                     

                      </DashboardBox>
                    </div>
                     )}
                     {  userObj &&  userObj.isKyc == generalModelStatuses.APPLIED && (
                        <h4>Please Wait for Admin Approval</h4>
                    )}
                         {  userObj &&  userObj.isKyc == generalModelStatuses.APPROVED && (
                          <>
                               <div className="col-12 col-md-6">
                    <label>  Pan : {userObj.pan}</label>
                    </div>
                    <div className="col-12 col-md-6">
                    <label> Aadhar : {userObj.aadhar}</label>
                    </div>


                    <div className="col-12 col-md-6">
                    <label>  Pan File :   {
                            userObj?.panFile &&
                            <img img src={generateFilePath(userObj?.panFile)} width="200px" height="200px"   alt="" />
                          }
                    </label>
                    </div>
                    <div className="">
                    Aadhar File :  {
                            userObj?.aadharFile &&
                            <img img src={generateFilePath(userObj?.aadharFile)} width="200px" height="200px"  alt="" />
                          }
                    </div>
                          </>
                         )}
                    {/* <div className="col-12 col-md-4">
                      <DashboardBox>
                        <div className="row">
                          <h5 className="blue-1 mb-4">Basic Info</h5>
                          <div className="col-12 mb-3">
                            <label>
                              CATEGORY<span className="red">*</span>
                            </label>
                            <Select options={options} />
                          </div>
                          <div className="col-12 mb-3">
                            <label>
                              Tags (Comma Separated)<span className="red">*</span>
                            </label>
                            <Select options={options} isMulti />
      
                            <div className="form-text fs-12 blue-1">Suggested Tags</div>
                          </div>
                          <div className="col-12 mb-3">
                            <label>
                              Image(1000x500)px<span className="red">*</span>
                            </label>
                            <FileUpload />
                          </div>
                          <div className="col-12 mb-3">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" name="category-status" value="option1" id="publish-checkbox" />
                              <label className="form-check-label fs-14" htmlFor="publish-checkbox">
                                Publish
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <CustomButton isBtn iconName="fa-solid fa-check" btnName="Save" />
                          </div>
                        </div>
                      </DashboardBox>
                    </div> */}
                  </div>
             
         
          </form>
        </div>
      </section>
    </main>
  );
}

export default KYC;
