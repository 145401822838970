import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/userOrder";

export const getAllOrders = (query) => {
  return axiosApiInstance.get(`${serverUrl}?${query}`);
};

export const getOrderById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getOrder/${id}`);
};

export const updateOrderById = async (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};
export const updateStatusOrderById = async (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/updateStatusOrderById/${id}`, obj);
};