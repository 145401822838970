import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { BANNERAdd, BANNERUpdate, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import { addSale, updateSaleById } from "../../../services/sale.service";
import { generateFilePath } from "../../Utility/utils";

function AddSale() {
  const dispatch = useDispatch();
  const bannerObj = useSelector((state) => state.banner.bannerObj);
  const [imageStr, setImageStr] = useState("");
  const [isUpdateBanner, setIsUpdateBanner] = useState(false);
  const [selectedBannerId, setselectedBannerId] = useState("");
  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };

  useEffect(() => {
    if (bannerObj) {
      setselectedBannerId(bannerObj._id);

      setImageStr(bannerObj.imageUrl);
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      setIsUpdateBanner(true);
    }
   
  }, [bannerObj]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (imageStr == "") {
        toastError("Please select an image")
      }
      let obj = {
        image: imageStr,
      };
    
    
      if (isUpdateBanner) {
   let { data: res } = await updateSaleById(selectedBannerId,obj)
   if (res.message) {
    toastSuccess(res.message)

  }
      } else {
        let { data: res } = await addSale(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
  setIsUpdateBanner(false);
  dispatch(SetBANNERObj(null));
     
    }
    catch (err) {
      toastError(err)
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Add New Sale</h5>
          <form className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Sale Information</h5>
                    <div className="col-12">
                      <label>Sale (150x150)PX</label>
                      <FileUpload onFileChange={(val) => setImageStr(val)} />
                      {
                        imageStr.includes("base64") ? (
                          <img src={imageStr} width="100px" />
                          
                        ) : (
                          <img src={generateFilePath(imageStr)} width="100px" />
                        )
                      }
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div>

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddSale;
