import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Switch } from "@mui/material";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate, SetCATEGORYObj } from "../../redux/actions/Category/Category.actions";
import { SETSTATEOBJ, STATEADD, STATEGET, STATEUPDATE } from "../../redux/actions/State/States.actions";
import { CITYGET } from "../../redux/actions/City/City.actions";
import { AREAADD, AREAGET, AREAUPDATE, SETAREAOBJ } from "../../redux/actions/Area/Area.actions";
import { WAREHOUSEADD, WAREHOUSEUPDATE, SETWAREHOUSEOBJ } from "../../redux/actions/WareHouse/WareHouse.actions";
import { setUserObj, usersGet } from "../../redux/actions/Users/users.actions";
import { getUser, updateUser } from "../../services/users.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
function AddStore({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isStore, setIsStore] = useState(false);
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const [stateId, setStateId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const [cityArr, setCityArr] = useState([]);
    const [stateArr, setStateArr] = useState([]);
    const [wareHouseArr, setWareHouseArr] = useState([]);
    const [areaArr, setAreaArr] = useState([]);
    const [cityId, setCityId] = useState("");
    const [citiesObj, setCitiesObj] = useState({});
    const [storeId, setStoreId] = useState("");

    const [areaId, setAreaId] = useState("");
    const [wareHouseId, setWareHouseId] = useState("");
    const [areasObj, setAreasObj] = useState({});

    const statesObj = useSelector((state) => state.states.statesObj);
    const cityObj = useSelector((state) => state.city.citiesObj);
    const statesArr = useSelector((state) => state.states.states);
    const citiesArr = useSelector((state) => state.city.cities);
    const areasArr = useSelector((state) => state.area.areas);
    const areaObj = useSelector((state) => state.area.areasObj);
    const wareHouseObj = useSelector((state) => state.warehouse.warehousesObj);
    const storeObj = useSelector((state) => state.users.userObj);
    const handleSubmit = async () => {
        try {
            if (!wareHouseId) {
                toastError("wareHouse is mandatory")
                return
            }
            let obj = {
             wareHouseId,
            }

            console.log(wareHouseId,"wareHouseId")
            if(storeId && storeObj){
             let { data: res } = await updateUser(obj, storeId);
            if (res.message) {
                toastSuccess(res.message)
                handlleGetAllWareHouse();
                setWareHouseId("")
                dispatch(setUserObj({}))
            }
            }
       
        }
        catch (err) {
            toastError(err)
        }
    }




    const handlleGetAllWareHouse = async () =>{
        try {
            let { data: response } = await getUser('role=WAREHOUSE,ADMIN') ;
            if(response.data){
                setWareHouseArr(response.data);
            }

        } catch (error) {
            toastError(error)
        }
    }



    useEffect(() => {
        handlleGetAllWareHouse();
    }, []);

    useEffect(() => {
        
        if(storeObj){
                setName(storeObj.name);
                setEmail(storeObj.name);
                setStoreId(storeObj._id);
        }
    }, [storeObj]);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name:{name}<br/>
                        Email:{email}<br/>
                    </label>
                </div>
                <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                        Ware House <span className="red">*</span>
                    </label>
                    <select className="form-control"   value={wareHouseId}
                        onChange={(e) => {
                            console.log(e.value, "asd")
                            setWareHouseId(e.target.value);
                          
                        }} >
                        <option>Select from options</option>
                    {
                        wareHouseArr && wareHouseArr.length > 0 && 
                        wareHouseArr.map(el =><option value={el._id}>{`${el.name} - ${el.email}` }</option> )
                    }
                    </select>
                    {/* <Select
                        options={wareHouseArr && wareHouseArr.length > 0 && wareHouseArr.map(el => { return { ...el, value: el._id, label: `${el.name} - ${el.email}` } })}
                        placeholder="Select from options"
                         value={wareHouseId}
                        onChange={(e) => {
                            console.log(e.value, "asd")
                            setWareHouseId(e.value);
                          
                        }}
                    /> */}
                </div>
                {/* <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                        City <span className="red">*</span>
                    </label>
                    <Select
                        options={cityArr && cityArr.length && cityArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                        placeholder="Select from options"
                        defaultInputValue={cityId} value={citiesObj}
                        onChange={(e) => {
                            console.log(e, "asd")
                            setCityId(e.value);
                            setCitiesObj(e);
                            dispatch(AREAGET(`cityId=${e.value}`));

                        }}
                    />
                </div>
                <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                        Area <span className="red">*</span>
                    </label>
                    <Select
                        options={areaArr && areaArr.length && areaArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                        placeholder="Select from options"
                        defaultInputValue={areaId} value={areasObj}
                        onChange={(e) => {
                            console.log(e, "area")
                            setAreaId(e.value);
                            setAreasObj(e);

                        }}
                    />
                </div>
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        User Name <span className="red">*</span>
                    </label>
                    <input value={userName} onChange={(event) => setUserName(event.target.value)} type="text" className="form-control" />
                </div>
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        User Email <span className="red">*</span>
                    </label>
                    <input value={email} onChange={(event) => setEmail(event.target.value)} type="text" className="form-control" />
                </div>
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        User Password <span className="red">*</span>
                    </label>
                    <input value={password} onChange={(event) => setPassword(event.target.value)} type="password" className="form-control" />
                </div>
                <div className="col-12">
                    <label className="blue-1 fs-12 me-5">
                        Is Store  <span className="red">*</span>
                    </label>
                    <Switch onChange={(e) => setIsStore(!isStore)} checked={isStore} />

                </div> */}
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onChange={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onChange={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleSubmit} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddStore;
