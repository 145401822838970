import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/productInternalOrder";

export const add = async (formData) => {
  // console.log(axiosApiInstance)
  return await axiosApiInstance.post(`${serverUrl}/addInternalOrder`, formData);
};

export const get = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAllInternalOrderByUserId?${query}`);
};
export const getInternalOrderById = (id,query =null) => {
  return axiosApiInstance.get(`${serverUrl}/getAllInternalOrderById/${id}?${query}`);
};

export const getAllInternalOrderProductByUser = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getAllInternalOrderProductByUser?${query}`);
};
// export const deleteById = (id) => {
//   return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
// };

export const internalOrderUpdateStatus = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/internalOrderUpdateStatus/${id}`, formData);
};

export const getSingleProductStockByRoleById = (id,query) => {
  return axiosApiInstance.get(`${serverUrl}/getSingleProductStockByRole/${id}?${query}`);
};

