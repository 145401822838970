import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { SetPRODUCTObj, getProductStockByRole } from "../../redux/actions/Product/Product.actions";
import { rolesObj } from "../../utils/roles";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { INTERNAL_ORDER_ORDER_FOR, generalModelStatuses } from "../Utility/constants";
import { toastError } from "../Utility/ToastUtils";
import { getCurrentStocks } from "../../services/index.service";


function StockReport() {
    const dispatch = useDispatch();

    const [stockArr, setStockArr] = useState([]);

    const handleGetProducts = async () => {
        try {
            const { data: res } = await getCurrentStocks();
            if (res) {
                console.log(res.data)
                setStockArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }

    }
    useEffect(() => {
        handleGetProducts()
    }, []);







    const product_sale_columns = [

        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row?.product?.name,
        },

        {
            name: "SKU",
            selector: (row) => row?.product?.sku,
        },


        {
            name: "Stock",
            selector: (row) => row?.quantity,
        },



    ];

    return (
        <main>

            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1">Current Stock Report</h5>
                            </div>

                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={stockArr && stockArr.length > 0 ? stockArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default StockReport;
