import {
  add,
  get,
  deleteById,
  updateById,
} from "../../../services/adminOrderProductStock.service";

export const ADMINPRODUCTORDERSTOCK_ADD = "ADMINPRODUCTORDERSTOCK_ADD";
export const ADMINPRODUCTORDERSTOCK_ADD_SUCCESS =
  "ADMINPRODUCTORDERSTOCK_ADD_SUCCESS";
export const ADMINPRODUCTORDERSTOCK_ADD_FAIL =
  "ADMINPRODUCTORDERSTOCK_ADD_FAIL";

export const GET_ALL_ADMINPRODUCTORDERSTOCKS =
  "GET_ALL_ADMINPRODUCTORDERSTOCKS";
export const GET_ALL_ADMINPRODUCTORDERSTOCKS_SUCCESS =
  "GET_ALL_ADMINPRODUCTORDERSTOCKS_SUCCESS";
export const GET_ALL_ADMINPRODUCTORDERSTOCKS_FAIL =
  "GET_ALL_ADMINPRODUCTORDERSTOCKS_FAIL";

export const UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID =
  "UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID";
export const UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS =
  "UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS";
export const UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL =
  "UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL";

export const SET_ADMINPRODUCTORDERSTOCK_OBJ = "SET_ADMINPRODUCTORDERSTOCK_OBJ";
export const SET_ADMINPRODUCTORDERSTOCK_OBJ_SUCCESS =
  "SET_ADMINPRODUCTORDERSTOCK_OBJ_SUCCESS";
export const SET_ADMINPRODUCTORDERSTOCK_OBJ_FAIL =
  "SET_ADMINPRODUCTORDERSTOCK_OBJ_FAIL";

export const GET_ADMINPRODUCTORDERSTOCK_BY_ID =
  "GET_ADMINPRODUCTORDERSTOCK_BY_ID";
export const GET_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS =
  "GET_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS";
export const GET_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL =
  "GET_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL";

export const DELETE_ADMINPRODUCTORDERSTOCK_BY_ID =
  "DELETE_ADMINPRODUCTORDERSTOCK_BY_ID";
export const DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS =
  "DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS";
export const DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL =
  "DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL";

export const GET_RELATED_ADMINPRODUCTORDERSTOCKS =
  "GET_RELATED_ADMINPRODUCTORDERSTOCKS";
export const GET_RELATED_ADMINPRODUCTORDERSTOCKS_SUCCESS =
  "GET_RELATED_ADMINPRODUCTORDERSTOCKS_SUCCESS";
export const GET_RELATED_ADMINPRODUCTORDERSTOCKS_FAIL =
  "GET_RELATED_ADMINPRODUCTORDERSTOCKS_FAIL";
// export const GET_ALL_NESTED_CATEGORIES = "GET_ALL_NESTED_CATEGORIES";
// export const GET_ALL_NESTED_CATEGORIES_SUCCESS = "GET_ALL_NESTED_CATEGORIES_SUCCESS";
// export const GET_ALL_NESTED_CATEGORIES_FAIL = "GET_ALL_NESTED_CATEGORIES_FAIL";

export const ADMINPRODUCTORDERSTOCKAdd = (formData) => async (dispatch) => {
  try {
    // console.log(formData, "formData");
    dispatch({ type: ADMINPRODUCTORDERSTOCK_ADD });
    let { data: response } = await add(formData);
    // console.log(response, "response in action");
    if (response) {
      console.log(response, "response in action");
      dispatch({
        type: ADMINPRODUCTORDERSTOCK_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ADMINPRODUCTORDERSTOCK_ADD_FAIL, payload: err });
  }
};

export const ADMINPRODUCTORDERSTOCKGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ADMINPRODUCTORDERSTOCKS });
    let { data: response } = await get(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_ADMINPRODUCTORDERSTOCKS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: ADMINPRODUCTORDERSTOCK_ADD_FAIL, payload: err });
  }
};

export const SetADMINPRODUCTORDERSTOCKObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_ADMINPRODUCTORDERSTOCK_OBJ });
    if (formData) {
      dispatch({
        type: SET_ADMINPRODUCTORDERSTOCK_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_ADMINPRODUCTORDERSTOCK_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_ADMINPRODUCTORDERSTOCK_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const ADMINPRODUCTORDERSTOCKUpdate =
  (formData, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID });
      let { data: response } = await updateById(formData, id);
      if (response) {
        console.log(response, "response");
        dispatch({
          type: UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS,
          payload: response.message,
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL,
        payload: err,
      });
    }
  };

export const ADMINPRODUCTORDERSTOCKDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ADMINPRODUCTORDERSTOCK_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      console.log(response, "resonse ina ction");
      dispatch(ADMINPRODUCTORDERSTOCKGet);
      dispatch({
        type: DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS,
        payload: {
          message: response.message,
          // deletedADMINPRODUCTORDERSTOCK: id,
        },
      });
      dispatch(ADMINPRODUCTORDERSTOCKGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL, payload: err });
  }
};
