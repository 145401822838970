import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as ADMINPRODUCTORDERSTOCK from "../../actions/AdminProductOrderStocks/AdminProductOrderStocks.actions";

const initialState = {
  adminProductOrderStock: null,
  adminProductOrderStockObj: null,
  loading: false,
  error: null,
};

export const AdminProductOrderStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMINPRODUCTORDERSTOCK.ADMINPRODUCTORDERSTOCK_ADD:
      return {
        ...state,
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.ADMINPRODUCTORDERSTOCK_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case ADMINPRODUCTORDERSTOCK.ADMINPRODUCTORDERSTOCK_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMINPRODUCTORDERSTOCK.UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS:
      console.log(
        "UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS",
        "UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS"
      );
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        adminProductOrderStock: action.payload.data,
      };
    case ADMINPRODUCTORDERSTOCK.UPDATE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMINPRODUCTORDERSTOCK.GET_ALL_ADMINPRODUCTORDERSTOCKS:
      return {
        ...state,
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.GET_ALL_ADMINPRODUCTORDERSTOCKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        adminProductOrderStock: action.payload.data,
      };
    case ADMINPRODUCTORDERSTOCK.GET_ALL_ADMINPRODUCTORDERSTOCKS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMINPRODUCTORDERSTOCK.GET_RELATED_ADMINPRODUCTORDERSTOCKS:
      return {
        ...state,
        ADMINPRODUCTORDERSTOCKs: [],
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.GET_RELATED_ADMINPRODUCTORDERSTOCKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        adminProductOrderStock: action.payload.data,
      };
    case ADMINPRODUCTORDERSTOCK.GET_RELATED_ADMINPRODUCTORDERSTOCKS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMINPRODUCTORDERSTOCK.DELETE_ADMINPRODUCTORDERSTOCK_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        adminProductOrderStock: [
          ...state.adminProductOrderStock.filter(
            (el) => el?._id != action?.payload?.deletedADMINPRODUCTORDERSTOCK
          ),
        ],
        loading: false,
        error: null,
      };
    case ADMINPRODUCTORDERSTOCK.DELETE_ADMINPRODUCTORDERSTOCK_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMINPRODUCTORDERSTOCK.SET_ADMINPRODUCTORDERSTOCK_OBJ:
      return {
        ...state,
        loading: true,
      };
    case ADMINPRODUCTORDERSTOCK.SET_ADMINPRODUCTORDERSTOCK_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        adminProductOrderStockObj: action.payload.data,
        loading: false,
        error: null,
      };
    case ADMINPRODUCTORDERSTOCK.SET_ADMINPRODUCTORDERSTOCK_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
