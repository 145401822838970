import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as SERVICE from "../../actions/Service/Service.actions";

const initialService = {
  services: null,
  serviceObj: null,
  loading: false,
  error: null,
};

export const serviceReducer = (service = initialService, action) => {
  switch (action.type) {
    case SERVICE.SERVICE_ADD:
      return {
        ...service,
        loading: true,
      };
    case SERVICE.SERVICE_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...service,
        loading: false,
      };
    case SERVICE.SERVICE_ADD_FAIL:
      toastError(action.payload);
      return {
        ...service,
        loading: false,
        error: action.payload,
      };

    case SERVICE.GET_ALL_SERVICES:
      return {
        ...service,
        loading: true,
      };
    case SERVICE.GET_ALL_SERVICES_SUCCESS:
      return {
        ...service,
        loading: false,
        error: null,
        services: action.payload.data,
      };
    case SERVICE.GET_ALL_SERVICES_FAIL:
      toastError(action.payload);
      return {
        ...service,
        loading: false,
        error: action.payload,
      };

    case SERVICE.DELETE_SERVICE_BY_ID:
      return {
        ...service,
        loading: true,
      };
    case SERVICE.DELETE_SERVICE_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...service,
        loading: false,
        error: null,
      };
    case SERVICE.DELETE_SERVICE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...service,
        loading: false,
        error: action.payload,
      };

    case SERVICE.UPDATE_SERVICE_BY_ID:
      return {
        ...service,
        loading: true,
      };
    case SERVICE.UPDATE_SERVICE_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...service,
        loading: false,
        error: null,
      };
    case SERVICE.UPDATE_SERVICE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...service,
        loading: false,
        error: action.payload,
      };

    case SERVICE.SET_SERVICE_OBJ:
      return {
        ...service,
        loading: true,
      };
    case SERVICE.SET_SERVICE_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...service,
        serviceObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SERVICE.SET_SERVICE_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...service,
        loading: false,
        error: action.payload,
      };

    default:
      return service;
  }
};
