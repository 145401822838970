import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate, SetCATEGORYObj } from "../../redux/actions/Category/Category.actions";
import { SETSERVICEOBJ, SERVICEADD, SERVICEUPDATE } from "../../redux/actions/Service/Service.actions";
import { generateFilePath } from "../Utility/utils";
function AddService({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const [image, setImageStr] = useState("");


    const servicesObj = useSelector((state) => state.service.serviceObj);
    const handleAddCategory = () => {
        let obj = {
            name,
            image,
            status,
        };
        console.log(obj, "category obj");

        if (servicesObj?._id) {
            dispatch(SERVICEUPDATE(servicesObj._id, obj));
            dispatch(SETSERVICEOBJ(null))
            setName("");
            setStatus("");
            setImageStr("");
        } else {
            dispatch(SERVICEADD(obj));
        }
    };

    useEffect(() => {
        if (servicesObj) {
            setName(servicesObj?.name);
            setStatus(servicesObj?.status);
            setImageStr(servicesObj?.image);
        }

        // return () => {
        //     dispatch(SETSERVICEOBJ(null));
        // };
    }, [servicesObj]);

    // useEffect(() => {
    //     dispatch(CATEGORYGet());
    // }, []);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>

                <div className="col-12">
                    <label className="blue-1 fs-12">UPLOAD PHOTO</label>
                    <FileUpload onFileChange={(val)=>setImageStr(val)} />
                    <div className="form-text fs-12">(Ratio: (225 X 225)PX)</div>
                    {
                        image !="" && (
                            image.includes("base64") ? (
                                <img src={image} alt="no imahe" width="100px" />
  
                          ) :(
  
                              <img src={generateFilePath(image)} alt="no imahe" width="100px" />
  
  
                          )
                        )
                    }
                    </div>

                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddService;
