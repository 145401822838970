// logo
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url;


export const getCurrentStocks = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getCurrentStocks`);
};

export const getSalesReport = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getSalesReport`);
};



export const getProductOrderReport = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getProductOrderReport`);
};
