// logo
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/offer";

export const addOffer = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getOffer = (query) => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteOfferById = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateOfferById = (id,formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
