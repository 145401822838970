import React, { useEffect, useState } from "react";
import { businessNatureObj, businessTypeObj, rolesObj } from "../../utils/roles";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import Select from "react-select";

import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addUser, updateUser } from "../../services/users.service";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../Utility/FileUpload";
import { useParams } from "react-router-dom";
import { getUserById } from "../../redux/actions/Users/users.actions";
import { AREAGET } from "../../redux/actions/Area/Area.actions";
import { CITYGET } from "../../redux/actions/City/City.actions";
import { STATEGET } from "../../redux/actions/State/States.actions";
function EditUser() {
    const dispatch = useDispatch()
    const params = useParams()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState(false);
    const [role, setRole] = useState(rolesObj.BUYER);
    const userObj = useSelector(state => state.users.userObj)
    const [rolesArr, setRolesArr] = useState([{ value: "SUBADMIN", label: "SUBADMIN" }, { value: "SELLER", label: "SELLER" }, { value: "BUYER", label: "BUYER" }]);
    const [stateId, setStateId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const [cityArr, setCityArr] = useState([]);
    const [stateArr, setStateArr] = useState([]);
    const [areaArr, setAreaArr] = useState([]);
    const [cityId, setCityId] = useState("");
    const [citiesObj, setCitiesObj] = useState({});
    const [areaId, setAreaId] = useState("");
    const [areasObj, setAreasObj] = useState({});

    const statesArr = useSelector((state) => state.states.states);
    const citiesArr = useSelector((state) => state.city.cities);
    const areasArr = useSelector((state) => state.area.areas);

    const handleSubmit = async () => {
        try {
            if (name == "") {
                toastError("Name is mandatory")
                return
            }
            else if (email == "") {
                toastError("Email is mandatory")
                return
            }
            else if (phone == "") {
                toastError("Phone is mandatory")
                return
            }
            else if (phone.length != 10) {
                toastError("Invalid Phone number")
                return
            }


            let obj = {
                name,
                email,
                phone,
                password,
                isActive: status,
                role,
            }




            // if (role == rolesObj.SUBADMIN) {
            //     obj.permissionsArr = permissionsArr.map(el => {
            //         el.permissionName = el.name;
            //         return el
            //     })
            // }
            let { data: res } = await updateUser(obj, params.id);
            if (res.message) {
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err)
        }
    }




    const handleGetUserById = () => {
        dispatch(getUserById(params.id))
    }



    useEffect(() => {
        handleGetUserById()
    }, [])


    useEffect(() => {


        if (statesArr) {
            setStateArr(statesArr)
        }
        if (citiesArr) {
            setCityArr(citiesArr)
        }
        if (areasArr) {
            setAreaArr(areasArr)
        }
    }, [statesArr, citiesArr, areasArr]);


    useEffect(() => {
        if (userObj) {
            setName(userObj.name ? userObj.name : "");
            setEmail(userObj.email ? userObj.email : "");
            setPhone(userObj.phone ? userObj.phone : "");
            setStatus(userObj.status ? userObj.status : "");
            setRole(userObj.role)
            setStateId(userObj?.stateId);
            setCityId(userObj?.cityId);
            setAreaId(userObj?.areaId);
            if (stateArr && stateArr.length > 0) {
                setStateObj(...stateArr.filter(el => el._id == userObj?.stateId).map(el => { return { ...el, label: el.name, value: el._id } }))
            }
            setCitiesObj(...citiesArr.filter(el => el._id == userObj?.cityId).map(el => { return { ...el, label: el.name, value: el._id } }))
            setAreasObj(...areasArr.filter(el => el._id == userObj?.areaId).map(el => { return { ...el, label: el.name, value: el._id } }))
            console.log(userObj, "userObj")
        }
    }, [userObj, userObj, stateArr, cityArr, areaArr, citiesArr, areasArr])


    useEffect(() => {
        dispatch(STATEGET());
        dispatch(CITYGET());
        dispatch(AREAGET());
    }, [userObj])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <h5 className="blue-1 mb-4">Add New User</h5>
                    <DashboardBox>
                        <form className="form row">
                            <h5 className="blue-1 mb-4">Basic Info</h5>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Name <span className="red">*</span>
                                </label>
                                <input
                                    name="first_name"
                                    className="form-control"
                                    type="text"
                                    required=""
                                    onChange={(e) => setName(e.target.value)} value={name}
                                />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>Phone</label>
                                <input
                                    name="last_name"
                                    className="form-control"
                                    type="text"
                                    disabled
                                    required=""
                                    maxLength={10}
                                    onChange={(e) => setPhone(e.target.value)} value={phone}
                                />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Email Address or Phone Number
                                    <span className="red">*</span>
                                </label>
                                <input disabled onChange={(e) => setEmail(e.target.value)} value={email} name="text" className="form-control" type="email" />
                            </div>






                        </form>
                        <div className="col-12 mt-2 text-center">
                            <CustomButton
                                ClickEvent={() => handleSubmit()}
                                isBtn
                                iconName="fa-solid fa-check"
                                btnName="Submit"
                            />
                        </div>
                    </DashboardBox>
                </div>
            </section>
        </main >
    );
}

export default EditUser;
