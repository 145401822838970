import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CITYGET } from "../../redux/actions/City/City.actions";
import { PRODUCTGet } from "../../redux/actions/Product/Product.actions";
import { getProductsAndAttributesAsProduct } from "../../services/product.service";
import SettlementDetails from "../dashboard/SettlementDetails";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { generalModelStatuses, INTERNAL_ORDER_ORDER_FOR, INTERNAL_ORDER_ORDER_TYPE } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import {
  INTERNALORDERAdd,
  INTERNALORDERGet,
  SetINTERNALORDERObj,
  INTERNALORDERUpdate,
  INTERNALORDERDelete,
} from "../../redux/actions/InternalOrder/InternalOrder.actions";
import { getAllInternalOrderProductByUser } from "../../services/InternalOrder.services";
import { getAdmin } from "../../services/users.service";
import { rolesObj } from "../../utils/roles";
export default function InternalOrders() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [warhouseStore, setwarhouseStore] = useState(false);

  const [name, setName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, SetAddress] = useState("");
  const [country, SetCountry] = useState("");
  const [state, SetState] = useState("");
  const [city, SetCity] = useState("");
  const [postalCode, SetPostalCode] = useState("");
  const [prevObjId, SetPrevObjId] = useState("");
  const [adminId, setAdminId] = useState("");

  const [shippingCharges, setShippingCharges] = useState(0);
  const internalOrderObj = useSelector((state) => state?.InternalOrder?.internalOrderObj);
  const role = useSelector((state) => state?.auth?.role);
  const user = useSelector((state) => state?.auth?.user);
  const [admin, setAdmin] = useState("")
  useEffect(() => {
    if (internalOrderObj) {
      setName(internalOrderObj?.name);
      setStatus(internalOrderObj?.status);
      setSelectAll(internalOrderObj?.selectAll);
      SetEmail(internalOrderObj?.email);
      SetPhone(internalOrderObj?.phone);
      SetAddress(internalOrderObj?.address);
      SetCountry(internalOrderObj?.country);
      SetState(internalOrderObj?.state);
      SetCity(internalOrderObj?.city);
      SetPostalCode(internalOrderObj?.postalCode);
      SetPrevObjId(internalOrderObj?._id);
    }
    // return () => {
    //     dispatch(SetINTERNALORDERObj(null));
    // };
  }, [internalOrderObj]);

  useEffect(() => {
    if (admin) {

      setName(admin.name ?? "admin");
      SetEmail(admin?.email);
      SetPhone(admin?.phone);
      SetAddress(admin?.address);
      SetCountry(admin.country ?? 'India');
      SetState(admin?.stateObj?.name);
      SetCity(admin?.cityObj?.name);
      SetPostalCode(admin?.pincode);
      setAdminId(admin._id);
      console.log(admin, "admin")

      console.log(adminId, "adminID")

    }

  }, [admin]);
  useEffect(() => {
    if (adminId && role == rolesObj.STORE) {
      handleGetProductsAndAttributesAsProduct();

    }
  }, [adminId]);

  const handlsetAdmin = async () => {

    let adminUser = await getAdmin();;
    if (adminUser?.data?.data) {
      setAdmin(adminUser?.data?.data);
      setAdminId(adminUser?.data?.data?._id);
      if (role == rolesObj.STORE && adminUser?.data.role != rolesObj.ADMIN) {
        setwarhouseStore(true)
      }
    } else {
      // setAdmin(null);
    }
  }
  const handleProductSelect = (val) => {
    setSelectedProductArr([...val]);
  };
  useDispatch(() => {
    console.log(setSelectedProductArr, "setSelectedProductArr1  ");
  }, [selectedProductArr]);
  const handleGetProductsAndAttributesAsProduct = async () => {
    try {
      let res = {};
      //  if (role == rolesObj.STORE && admin?.role != rolesObj.ADMIN) {

      let query = `type=${INTERNAL_ORDER_ORDER_TYPE.SELL}`;
      let orderFor = INTERNAL_ORDER_ORDER_FOR.ADMIN_STOCK_IN;
      if (role == rolesObj.WAREHOUSE) {
        orderFor = INTERNAL_ORDER_ORDER_FOR.WAREHOUSE_STOCK_IN;
      }
      if (role == rolesObj.STORE) {
        orderFor = INTERNAL_ORDER_ORDER_FOR.STORE_STOCK_IN;
      }
      query = `orderFor=${orderFor}&currentStatus=${generalModelStatuses.DELIVERED}&type=${INTERNAL_ORDER_ORDER_TYPE.BUY}`;

      if (role != rolesObj.ADMIN && adminId) {
        console.log(adminId, "admin------------------")

        query += `&orderedFromId=${adminId}`
        query += `&orderedToId=${user?._id}`
        let { data: response } = await getAllInternalOrderProductByUser(query);
        res = response;

      } else {
        let { data: response } = await getAllInternalOrderProductByUser(query);
        res = response;

      }
      if (res.data) {
        setProductInternalArr(res.data);
      }


    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    if (productInternalArr && productInternalArr.length > 0 && selectAll) {
      setSelectedProductArr([
        ...productInternalArr.map((el) => {
          return { ...el, label: el?.name, value: el?.name, incomingStock: el.stock };
        }),
      ]);
    } else {
      setSelectedProductArr([]);
    }
  }, [selectAll, productInternalArr]);

  const handleValueChange = (index, value, key) => {
    if (!(value < 0)) {

      if (key == 'incomingStock' && role!=rolesObj.WAREHOUSE) {
        let totolStock = selectedProductArr[index]?.stock;
        console.log(totolStock, "totolStocktotolStocktotolStock")
        if (value > totolStock) {
          toastError("Incoming Stock Not More than Current Stock")
          return;
        }
      }

      let tempArr = selectedProductArr;
      tempArr[index].price = tempArr[index].buyingPrice
      tempArr[index][key] = value
      setSelectedProductArr([...tempArr])
    }
  };



  const percentage = (percent, total) => {
    return ((percent / 100) * total)
  }





  useEffect(() => {
    handlsetAdmin();

    if (role != rolesObj.STORE) {
      handleGetProductsAndAttributesAsProduct();
    }
  }, []);


  const calculateTotalQuantity = () => {
    return selectedProductArr.reduce((acc, el) => acc + parseInt(el.incomingStock), 0).toFixed(0)
  }
  const calculateTotal = () => {
    return selectedProductArr.reduce((acc, el) => acc + (parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price))), 0).toFixed(2)
  }
  const calculateGrandTotal = () => {
    return Math.round((selectedProductArr.reduce((acc, el) => acc + (parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price))), 0) + parseInt(shippingCharges))).toFixed(2)
  }
  const calculateGrandWithoutGstTotal = () => {
    return (selectedProductArr.reduce((acc, el) => acc + (parseInt(el.incomingStock) * (parseInt(el.price))), 0) + parseInt(shippingCharges)).toFixed(2)
  }
  const calculateRoundOff = () => {
    let final = (selectedProductArr.reduce((acc, el) => acc + (parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price))), 0) + parseInt(shippingCharges)).toFixed(2)
    let result = (final - Math.round(final))
    return Math.abs(result).toFixed(2)
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (name == "") {
      toastError("Name is mandatory");
      return;
    } else if (email == "") {
      toastError("Email is mandatory");
      return;
    } else if (address == "") {
      // toastError("Address is mandatory");
      // return;
    } else if (phone == "") {
      // toastError("Phone is mandatory");
      // return;
    } else if (country == "") {
      toastError("Country is mandatory");
      return;
    } else if (state == "") {
      toastError("State is mandatory");
      return;
    } else if (city == "") {
      toastError("City is mandatory");
      return;
    } else if (postalCode == "") {
      toastError("Postal code is mandatory");
      return;
    }
    else if (!(selectedProductArr.length > 0)) {
      toastError("Please select products to proceed");
      return;
    }
    else if (!(selectedProductArr.every(el => el.gst > 0))) {
      toastError("Please add gst to all the products");
      return;
    }
    else if (!(selectedProductArr.every(el => (el.sellingPrice > 0)))) {
      toastError("Please add selling price to all the products");
      return;
    }
    else if (!(selectedProductArr.every(el => el.price > 0))) {
      toastError("Please add buying price to all the products");
      return;
    }
    else if (!(selectedProductArr.every(el => el.incomingStock > 0))) {
      toastError("Please add stock to all the products");
      return;
    }

    let orderFor = INTERNAL_ORDER_ORDER_FOR.ADMIN_STOCK_IN;
    let orderedFromId = user?._id;
    let orderedToId = user?._id;

    let buyerDetails = {
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      state: user?.stateObj?.name,
      city: user?.cityObj?.name,
      postalCode: user?.pincode
    }

    if (role == 'WAREHOUSE') {
      orderFor = INTERNAL_ORDER_ORDER_FOR.WAREHOUSE_STOCK_IN;
      orderedFromId = adminId;

    }

    if (role == 'STORE') {
      orderFor = INTERNAL_ORDER_ORDER_FOR.STORE_STOCK_IN;
      orderedFromId = adminId;

    }

    let obj = {
      productsArr: selectedProductArr.map(el => {
        let obj = {
          ...el,
          batchId: el.batchId,
          subTotal: (parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock),
          totalPriceWithGst: (parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock),
          totalPriceWithoutGst: (parseInt(el.price)) * parseInt(el?.incomingStock)
        }
        return obj
      }),
      sellerDetails: {
        name,
        email,
        phone,
        address,
        country,
        state,
        city,
        postalCode,
      },
      buyerDetails,
      orderFor: orderFor,
      orderedToId,
      orderedFromId,
      type: INTERNAL_ORDER_ORDER_TYPE.SELL,
      subTotal: parseFloat(calculateTotal()),
      totalPriceWithGst: parseFloat(calculateGrandTotal()),
      totalPriceWithoutGst: parseFloat(calculateGrandWithoutGstTotal()),
      discountValue: 0,
      shippingCharge: shippingCharges,
      total: parseFloat(calculateGrandTotal()),
      roundOff: parseFloat(calculateRoundOff()),
    };

    dispatch(INTERNALORDERAdd(obj))

    // if (internalOrderObj && internalOrderObj?._id) {
    //   dispatch(INTERNALORDERUpdate(obj, prevObjId));
    // } else {
    //   dispatch(INTERNALORDERAdd(obj));
    // }
  };
  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="blue-1 m-0"> Products</h5>
                    <div
                      style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}
                    >
                      <input
                        checked={selectAll}
                        onChange={(e) => setSelectAll((prev) => !prev)}
                        type="checkbox"
                      />
                    </div>
                    <label>
                      {selectAll == false ? "Select" : "Un-Select"} All
                    </label>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <label>
                      Please select products from the list
                      <span className="red">*</span>
                    </label>
                    <Select
                      options={[
                        ...productInternalArr.map((el) => {
                          return { ...el, label: el?.name, value: el?.name };
                        }),
                      ]}
                      isMulti
                      placeholder="Select from Products to order"
                      value={selectedProductArr}
                      onChange={(e) => {
                        console.log(e, "asd");
                        handleProductSelect(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-4 ">&nbsp;</h5>
                  <div className="row">
                    {/* <div className="col-12 col-md-12 mselectAllb-3 ">
                      <label>
                        If address already added please select from the list
                        below <span className="red">*</span>
                      </label>
                      <Select
                        options={[
                          ...productInternalArr.map((el) => {
                            return { ...el, label: el?.name, value: el?.name };
                          }),
                        ]}
                        isMulti
                        placeholder="Select from Products to order"
                   
                      />
                    </div> */}

                    {/* {role == rolesObj.ADMIN ? (
                        <>
                        <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Email <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => SetEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Phone <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        onChange={(e) => SetPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Address <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={(e) => SetAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Country <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={country}
                        onChange={(e) => SetCountry(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        State <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state}
                        onChange={(e) => SetState(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        City <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={city}
                        onChange={(e) => SetCity(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Postal Code <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={postalCode}
                        onChange={(e) => SetPostalCode(e.target.value)}
                      />
                    </div>
                    </>



                      ):(

                        <>
                          <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Name   : {name}
                      </label>
                        
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Email : {email}
                      </label>
                     
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Phone : {phone} 
                      </label>
                    
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Address :{address}
                      </label>
               
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Country : {country}
                      </label>
                  
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        State  : {state}
                      </label>
                  
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        City : {city}
                      </label>
                   
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Postal Code : {postalCode}
                      </label>
                    
                    </div>
                    </>
                      )} */}

                  </div>
                  <div className="col-12">
                    {
                      (admin || role == rolesObj.ADMIN) ? (<CustomButton
                        btntype="button"
                        iconName="fa-solid fa-check"
                        btnName="Save"
                        isBtn
                        // small={makeChange ? true : false}
                        ClickEvent={handleSubmit}
                      />) : null
                    }

                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-9">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>

                          <th scope="col">Buying Price</th>
                          <th scope="col">Selling Price</th>
                          <th scope="col">Gst</th>
                          <th scope="col">Current Stock</th>
                          <th scope="col">Stock</th>
                          <th scope="col">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el.name}</th>
                                <td>{el?.buyingPrice}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={el?.sellingPrice}
                                    onChange={(e) =>
                                      handleValueChange(
                                        index,
                                        e.target.value,
                                        "sellingPrice"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {

                                      !(e.target.value > 100) &&

                                        handleValueChange(
                                          index,
                                          e.target.value,
                                          "gst"
                                        )
                                    }
                                    }
                                    value={el?.gst}
                                  />
                                </td>
                                <td>
                                  {el?.stock}
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      handleValueChange(
                                        index,
                                        e.target.value,
                                        "incomingStock"
                                      )
                                    }
                                    value={el?.incomingStock}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={((parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock)).toFixed(2)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-3" style={{ borderLeft: "solid 1px rgba(0,0,0,0.1)" }}>
                    <h5 className="blue-1 m-0">Summary</h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Total Items</th>
                          <th scope="col">{calculateTotalQuantity()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Sub Total </th>
                          <th scope="col">{calculateTotal()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Discount </th>
                          <th scope="col">0</th>
                        </tr>
                        <tr>
                          <th scope="col">Total Shipping Charges</th>
                          <th>
                            <input
                              type="number"
                              className="form-control"
                              value={shippingCharges}
                              onChange={(e) =>
                                setShippingCharges(e.target.value)
                              }
                            />
                          </th>
                        </tr>
                        <tr>
                          <th scope="col">Grant Total </th>
                          <th scope="col">{calculateGrandTotal()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Round Off </th>
                          <th scope="col">{calculateRoundOff()}</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
