import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { downloadCSV } from "../Utility/CSV";
import SearchBox from "../Utility/SearchBox";

import { AddModal } from "../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { CATEGORYDelete, CATEGORYGet, SetCATEGORYObj } from "../../redux/actions/Category/Category.actions";
import { toastError } from "../../utils/toastUtils";
import AddServices from "./AddService";
import { SETSERVICEOBJ, SERVICEDELETE, SERVICEGET } from "../../redux/actions/Service/Service.actions";
import { generateFilePath } from "../Utility/utils";
function ViewServices() {
    // ==============================================================================================================
    const dispatch = useDispatch();
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const servicesArr = useSelector((state) => state.service.services);
    const serviceObj = useSelector((state) => state.service.servicesObj);
    const [ModalData, setModalData] = useState({});
    const [displayServiceArr, setDisplayServicesArr] = useState([]);
    const [query, setQuery] = useState("");
    const handleCategoryEdit = (row) => {
        dispatch(SETSERVICEOBJ(row));
    };

    const [serviceMainArr, setServiceMainArr] = useState([]);

    const handleGet = () => {
        dispatch(SERVICEGET());
    };

    const handleCategoryDelete = (row) => {
        dispatch(SERVICEDELETE(row._id))
    }

    useEffect(() => {
        handleGet()
    }, [])

    useEffect(() => {
        if (servicesArr?.length) {
            setServiceMainArr(servicesArr)
            setDisplayServicesArr(servicesArr)
        }
    }, [servicesArr])

    const category_columns = [
        {
            name: "ID",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Image",
            selector: (row) =>     <img src={generateFilePath(row.image)} alt="no imahe" width="100px" />,
        },
        {
            name: "Status",
            minWidth: "210px",
            maxWidth: "211px",
            button: true,
            cell: (row) => <CustomButton greenBtn noIcon btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`} path={"/Product/Service"} />,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => <ActionIcon Uniquekey={row._id} remove edit deletePath="/Product/Service" onDeleteClick={() => handleCategoryDelete(row)} isRedirected={true} onEditClick={() => handleCategoryEdit(row)} editPath="/Product/Service" />,
        },
    ];

    // ==============================================================================================================

    const handleFilterByQuery = (e, requiredParametersArr) => {
        let tempArr = displayServiceArr.filter(el => {
            for (const ele of requiredParametersArr) {
                console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el")
                if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
                    // console.log("true")
                    return true;
                }
                else {
                    return false;
                }
            }
        })
        setQuery(e)
        setServiceMainArr([...tempArr])
        console.log([...tempArr], "...tempArr")
    }


    return (
        <main>
            <AddModal
                ModalBox={ModalBox}
                setModalBox={setModalBox}
                name={ModalName}
                ModalType={ModalType}
                data={ModalData}
            />
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{serviceObj && serviceObj.name ? "Edit Service" : "Add Service"}</h5>
                                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
                            </div>
                            <DashboardBox>
                                <AddServices />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1 m-0">Services List</h5>
                                <div className="d-flex gap-3 align-items-center">
                                    {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                                    <SearchBox setQuery={(e) => { handleFilterByQuery(e, ["name"]); }} query={query} extraClass="bg-white" />
                                </div>
                            </div>
                            <DashboardTable>
                                <DataTable columns={category_columns} data={serviceMainArr && serviceMainArr.length > 0 ? serviceMainArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ViewServices;
