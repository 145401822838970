import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate, SetCATEGORYObj } from "../../../redux/actions/Category/Category.actions";
import SelectNestedCategory from "./SelectNestedCategory";
import { display } from "@mui/system";
import { SERVICEGET } from "../../../redux/actions/Service/Service.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
function AddCategory({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isSearchable, setIsSearchable] = useState(false);
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [toggleSubCategory, setToggleSubCategory] = useState(false);
  const [subcategoryArr, setSubcategoryArr] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState('')
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState("");
  const [displayAt, setDisplayAt] = useState("");
  const [displayCategoryId, setDisplayAtCategoryId] = useState("");
  const [parentCategoryArr, setParentCategoryArr] = useState([]);
const [serviceId, setserviceId] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [bannerImageStr, setBannerImageStr] = useState("");
  const [position, setPosition] = useState("0");
  const [addSubCategory, setaddSubCategory] = useState(false);
const [serviceArr, setserviceArr] = useState([]);
  const [prevCategoryObj, setPrevCategoryObj] = useState(null);
  const [prevCategoryId, setPrevCategoryId] = useState(null);
  const categoryArr = useSelector((state) => state.category.categories);
  const categoryObj = useSelector((state) => state.category.categoryObj);
  const servicesReducxArr = useSelector((state) => state.service.services);

  const handleAddCategory = () => {

    if(name == "") {
      toastError("Please add name");
      return 0
  }
  if(serviceId == "") {
    toastError("Please add service");
    return 0
}
if(imageStr == "") {
  toastError("Please add image");
  return 0
}
    let obj = {
      name,
      status,
      isSearchable,
      imageStr,
      serviceId,
      bannerImageStr,
      parentCategoryId: parentCategoryId,
      displayAt,
      displayCategoryId,
      position,

    };
    console.log(obj, "category obj");

    if (categoryObj?._id) {
      dispatch(CATEGORYUpdate(prevCategoryId, obj));
    } else {
      dispatch(CATEGORYAdd(obj));
    }
    // clearForm();  
  };


  const  dsiplayAtArr = [

    {
      label:"Home",
      value:"home"
    },
    // {
    //   label:"Category",
    //   value:"category"
    // },


  ]

  const clearForm = () => {
    setName("");
    setSlug("");
    setIsSearchable("");
    setStatus("");
    setDisplayAt("");
    setPosition("");
    setImageStr("")
    setBannerImageStr("")

      setSelectedParentCategoryId("");
      setPrevCategoryObj("");
      setaddSubCategory(false);
    setPrevCategoryId("");
  }

  useEffect(() => {
    if(categoryArr && categoryArr.length > 0){
      setParentCategoryArr(categoryArr.filter(ele => ele.level == 1))
    }
  },[categoryArr])
  useEffect(() => {
    if(servicesReducxArr && servicesReducxArr.length > 0){
      setserviceArr(servicesReducxArr)
    }
  },[servicesReducxArr])

  useEffect(() => {
    if (categoryObj) {
      setName(categoryObj?.name);
      setSlug(categoryObj?.slug);
      setIsSearchable(categoryObj?.isSearchable);
      setStatus(categoryObj?.status);
      setDisplayAt(categoryObj?.displayAt);
      setPosition(categoryObj?.position);
      setserviceId(categoryObj?.serviceId)
      if(categoryObj?.categoryImage){
      setImageStr(categoryObj?.categoryImage)
      }
      if(categoryObj?.bannerImage){
        setBannerImageStr(categoryObj?.bannerImage)
        }

      if (categoryObj?.parentCategoryId) {
        setSelectedParentCategoryId(categoryObj?.parentCategoryId);
        setPrevCategoryObj({ label: categoryArr?.find((el) => el._id == categoryObj?.parentCategoryId)?.name, value: categoryObj.parentCategoryId });
        setaddSubCategory(true);
      }
      setPrevCategoryId(categoryObj?._id);
    }

    // return () => {
    //   dispatch(SetCATEGORYObj(null));
    // };
  }, [categoryObj]);

  useEffect(() => {
    dispatch(CATEGORYGet());
    dispatch(SERVICEGET(`status=${generalModelStatuses.APPROVED}`));

  }, []);

  const handleFileSet = (value) => {
    setImageStr(value);
  };
  const handleBannerFileSet = (value) => {
    setBannerImageStr(value);
  };

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
        </div>
        {/* <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            SLUG <span className="red">*</span>
          </label>
          <input value={slug} onChange={(event) => setSlug(event.target.value)} type="text" className="form-control" />
        </div> */}
          <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                <label className="blue-1 fs-12">
                  Service <span className="red">*</span>
                </label>
                <select className="form-control" value={serviceId} onChange={(e)=>{setserviceId(e.target.value)}}>
                    <option value="">Please Select Service</option>
                    {
                      serviceArr && serviceArr.map(ele => <option value={ele._id}>{ele.name}</option>)
                    }
                  </select>
              </div>
    
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Display at Page <span className="red">*</span>
          </label>
          <select className="form-control" value={displayAt} onChange={(e)=>{setDisplayAt(e.target.value)}}>
            <option value="">Please Select Option</option>
            {
              dsiplayAtArr && dsiplayAtArr.map(ele => <option value={ele.value}>{ele.label}</option>)
            }
          </select>
        </div>
          {
            ( displayAt == 'category' && parentCategoryArr && (parentCategoryArr.length > 0)) ? (

              <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
              <label className="blue-1 fs-12">
                Select Category Page <span className="red">*</span>
              </label>
              <select className="form-control" value={displayCategoryId} onChange={(e)=>{setDisplayAtCategoryId(e.target.value)}}>
                <option>Please Select Option</option>
                {
                  parentCategoryArr && parentCategoryArr.map(ele => <option value={ele._id}>{ele.name}</option>)
                }
              </select>
            </div>
            ) 
            :null
          }

            <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                <label className="blue-1 fs-12">
                  Display at Position <span className="red">*</span>
                </label>
                <select className="form-control" value={position} onChange={(e)=>{setPosition(e.target.value)}}>
                  <option value>Please Select Option</option>
                  <option value="0">None</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  
                </select>
              </div>

            
        {/* <div className={makeChange ? "col-12" : "col-12"}>
          <label className="blue-1 fs-12">
            ICON (TO USE THEMEFY ICON PLEASE TYPE HERE OR SELECT FONTAWESOME
            FROM LIST)
          </label>
          <input type="text" className="form-control" />
        </div> */}
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">SEARCHABLE</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" name="category-status" id="category-searchable-Radio1" checked={isSearchable} onClick={() => setIsSearchable(true)} />
              <label className="form-check-label fs-14" htmlFor="category-searchable-Radio1">
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" name="category-status" id="category-searchable-Radio2" checked={!isSearchable} onClick={() => setIsSearchable(false)} />
              <label className="form-check-label fs-14" htmlFor="category-searchable-Radio2">
                Inactive
              </label>
            </div>
          </div>
        </div>

        
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
              <label className="form-check-label fs-14" htmlFor="category-Radio1">
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
              <label className="form-check-label fs-14" htmlFor="category-Radio2">
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className={makeChange ? "col-12 col-md-4 d-flex align-items-end" : "col-12"}>
          <div className="form-check form-check-inline d-flex align-items-center pointer">
            <input
              className="form-check-input"
              type="checkbox"
              name="category-status"
              value="option1"
              id="add-as-sub-category"
              checked={addSubCategory}
              onChange={(e) => {
                if (e.target.checked) {
                  setaddSubCategory(true);
                } else {
                  setaddSubCategory(false);
                }
              }}
            />
            <label className="form-check-label fs-14 pointer" htmlFor="add-as-sub-category">
              Add as Sub Category {parentCategoryId}
            </label>
          </div>
        </div>
        {addSubCategory && (
          <SelectNestedCategory onChange={(val) => setParentCategoryId(val)} />
        )}
        
        <div className="col-12">
          <label className="blue-1 fs-12">UPLOAD PHOTO</label>
          <FileUpload onFileChange={handleFileSet} />
          <div className="form-text fs-12">(Ratio: (225 X 225)PX)</div>

          {
                        imageStr !="" && (
                            imageStr.includes("base64") ? (
                                <img src={imageStr} alt="no imahe" width="100px" />
  
                          ) :(
  
                              <img src={generateFilePath(imageStr)} alt="no imahe" width="100px" />
  
  
                          )
                        )
                    }
        </div>

        <div className="col-12">
          <label className="blue-1 fs-12">Upload Banner</label>
          <FileUpload onFileChange={handleBannerFileSet} />
          {
                        bannerImageStr !="" && (
                          bannerImageStr.includes("base64") ? (
                                <img src={bannerImageStr} alt="no imahe" width="100px" />
  
                          ) :(
  
                              <img src={generateFilePath(bannerImageStr)} alt="no imahe" width="100px" />
  
  
                          )
                        )
                    }
          {/* <div className="form-text fs-12">(: (225 X 225)PX)</div> */}
        </div>


        <div className="col-12">
          <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
