import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORYAdd, CATEGORYGet, CATEGORYUpdate, SetCATEGORYObj } from "../../../redux/actions/Category/Category.actions";
import { SETSUBSCRIPTIONOBJ, SUBSCRIPTIONADD, SUBSCRIPTIONUPDATE } from "../../../redux/actions/Subscription/Subscription.actions";
import { toastError } from "../../Utility/ToastUtils";
function AddSubscription({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);

    const [durationInDays, setDurationInDays] = useState(0)
    const [price, setPrice] = useState(0)
    const [description, setDescription] = useState("")
    const [messageArr, setMessageArr] = useState([{ message: "" }])


    const handleRulesAdd = () => {
        setMessageArr([
          ...messageArr,
          {
            name: "",
          },
        ]);
      };
    
      const handleRulesRemove = (inde) => {
          setMessageArr([
            ...messageArr.filter(
              (el, index) => index != inde
            ),
          ]);
      };
    

    const subscriptionsObj = useSelector((subscription) => subscription.subscription.subscriptionsObj);
    const handleAddCategory = () => {


      

        if (`${name}` === '') {
            toastError('Please Fill Name')
            return 0
          }
      
          if (`${price}` === '' || price <= 0) {
            toastError('Please Fill Price with a valid input (more than 0)')
            return 0
          }
      
          if (`${description}` === '') {
            toastError('Please Fill Description')
            return 0
          }
      
          if (`${durationInDays}` === '' || durationInDays <= 0) {
            toastError('Please Fill Duration In Days with a valid input (more than 0)')
            return 0
          }
          let obj = {
            name,
            status,
            price,
            description,
            durationInDays,
        };
        console.log(obj, "category obj");

        if (subscriptionsObj?._id) {
            dispatch(SUBSCRIPTIONUPDATE(subscriptionsObj._id, obj));
            dispatch(SETSUBSCRIPTIONOBJ(null))
        } else {
            dispatch(SUBSCRIPTIONADD(obj));
        }
    };

    useEffect(() => {
        if (subscriptionsObj) {
            setName(subscriptionsObj?.name);
            setStatus(subscriptionsObj?.status);
        }

        // return () => {
        //     dispatch(SETSUBSCRIPTIONOBJ(null));
        // };
    }, [subscriptionsObj]);

    // useEffect(() => {
    //     dispatch(CATEGORYGet());
    // }, []);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>

                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Desciption <span className="red">*</span>
                    </label>
                    <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
                </div>

                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                    Duration in days<span className="red">*</span>
                    </label>
                    <input value={durationInDays} onChange={(event) => setDurationInDays(event.target.value)} type="text" className="form-control" />
                </div>

                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                    Price<span className="red">*</span>
                    </label>
                    <input value={price} onChange={(event) => setPrice(event.target.value)} type="text" className="form-control" />
                </div>



                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                    Point Description<span className="red">*</span>
                    </label>

                    <button className="btn btn-success btn-sm ms-2" type="button"  onClick={()=>handleRulesAdd()} ><i className="fa fa-plus"></i></button>
          
                    {messageArr &&
                            messageArr.length > 0 &&
                            messageArr.map((elx, indexXX) => {
                            return (
                                <div key={indexXX} className="row my-3">
                                <div className="col-10 col-md-10">
                                    <label htmlFor={`${indexXX}heading${elx._id}`}>
                                    {`Rule ${indexXX + 1}`}
                                    </label>
                                    <input
                                    name="first_name"
                                    type="text"
                                    required=""
                                    className="form-control"
                                    value={elx.name}
                  
                />
              </div>
              <div className="col-md-2">
             
             <button type="button" onClick={()=>handleRulesRemove(indexXX)} className="btn mt-4 btn-danger btn-sm"><i className="fa fa-trash" ></i></button>

             </div>
            </div>
          );
        })}
            </div>
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddSubscription;
