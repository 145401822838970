import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CITYGET } from "../../redux/actions/City/City.actions";
import { PRODUCTGet } from "../../redux/actions/Product/Product.actions";
import { getProductsAndAttributesAsProduct } from "../../services/product.service";
import SettlementDetails from "../dashboard/SettlementDetails";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { generalModelStatuses, INTERNAL_ORDER_ORDER_TYPE } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import {
    INTERNALORDERAdd,
    INTERNALORDERGet,
    SetINTERNALORDERObj,
    INTERNALORDERUpdate,
    INTERNALORDERDelete,
    INTERNALORDERGETBYID,
} from "../../redux/actions/InternalOrder/InternalOrder.actions";
import { useParams } from "react-router-dom";
export default function ViewInternalOrders() {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const [productInternalArr, setProductInternalArr] = useState([]);
    const [selectedProductArr, setSelectedProductArr] = useState([]);
    const params = useParams()
    const [selectAll, setSelectAll] = useState(false);

    const [name, setName] = useState("");
    const [email, SetEmail] = useState("");
    const [phone, SetPhone] = useState("");
    const [address, SetAddress] = useState("");
    const [country, SetCountry] = useState("");
    const [state, SetState] = useState("");
    const [city, SetCity] = useState("");
    const [postalCode, SetPostalCode] = useState("");
    const [prevObjId, SetPrevObjId] = useState("");
    const [supplierObj, setSupplierObj] = useState("");

    const [shippingCharges, setShippingCharges] = useState(0);
    const internalOrderObj = useSelector(
        (state) => state.internalOrder.internalOrderObj
    );
    useEffect(() => {
        if (internalOrderObj) {
            setName(internalOrderObj?.sellerDetails?.name);
            SetEmail(internalOrderObj?.sellerDetails?.email);
            SetPhone(internalOrderObj?.sellerDetails?.phone);
            SetAddress(internalOrderObj?.sellerDetails?.address);
            SetCountry(internalOrderObj?.sellerDetails?.country);
            SetState(internalOrderObj?.sellerDetails?.state);
            SetCity(internalOrderObj?.sellerDetails?.city);
            SetPostalCode(internalOrderObj?.sellerDetails?.postalCode);
            setSelectedProductArr(internalOrderObj?.productsArr.map(el => ({ ...el, label: `${el.name} | ${el.variantName}`, value: `${el.name} | ${el.variantName}` })))
            setShippingCharges(internalOrderObj?.shippingCharge);
            setStatus(internalOrderObj?.orderStatus?.currentStatus);
            setSupplierObj(internalOrderObj?.supplierObj)

            // SetPrevObjId(internalOrderObj?.sellerDetails?._id);
        }
        return () => {
            dispatch(SetINTERNALORDERObj(null));
        };
    }, [internalOrderObj]);




    const handleProductSelect = (val) => {
        setSelectedProductArr([...val]);
    };
    const handleGetProductsAndAttributesAsProduct = async () => {
        try {
            let { data: res } = await getProductsAndAttributesAsProduct();
            if (res.data) {
                setProductInternalArr(res.data);
            }
        } catch (err) {
            toastError(err);
        }
    };


    const handleValueChange = (index, value, key) => {
        if (!(value < 0)) {
            let tempArr = selectedProductArr;
            tempArr[index][key] = value
            setSelectedProductArr([...tempArr])
        }
    };


    const percentage = (percent, total) => {
        return ((percent / 100) * total)
    }


    useEffect(() => {
        handleGetProductsAndAttributesAsProduct();
        dispatch(INTERNALORDERGETBYID(params.id))
    }, []);


    const calculateTotalQuantity = () => {
        return selectedProductArr.reduce((acc, el) => acc + parseInt(el.quantity), 0).toFixed(0)
    }
    const calculateTotal = () => {
        return selectedProductArr.reduce((acc, el) => acc + (parseInt(el.quantity) * (parseInt(el.buyingPrice) + percentage(el.igst, el.buyingPrice))), 0).toFixed(2)
    }
    const calculateGrandTotal = () => {
        return Math.round((selectedProductArr.reduce((acc, el) => acc + (parseInt(el.quantity) * (parseInt(el.buyingPrice) + percentage(el.igst, el.buyingPrice))), 0) + parseInt(shippingCharges))).toFixed(2)
    }
    const calculateGrandWithoutGstTotal = () => {
        return (selectedProductArr.reduce((acc, el) => acc + (parseInt(el.quantity) * (parseInt(el.buyingPrice))), 0) + parseInt(shippingCharges)).toFixed(2)
    }
    const calculateRoundOff = () => {
        let final = (selectedProductArr.reduce((acc, el) => acc + (parseInt(el.quantity) * (parseInt(el.buyingPrice) + percentage(el.igst, el.buyingPrice))), 0) + parseInt(shippingCharges)).toFixed(2)
        let result = (final - Math.round(final))
        return Math.abs(result).toFixed(2)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (name == "") {
            toastError("Name is mandatory");
            return;
        } else if (email == "") {
            toastError("Email is mandatory");
            return;
        } else if (address == "") {
            toastError("Address is mandatory");
            return;
        } else if (phone == "") {
            toastError("Phone is mandatory");
            return;
        } else if (country == "") {
            toastError("Country is mandatory");
            return;
        } else if (state == "") {
            toastError("State is mandatory");
            return;
        } else if (city == "") {
            toastError("City is mandatory");
            return;
        } else if (postalCode == "") {
            toastError("Postal code is mandatory");
            return;
        }
        else if (!(selectedProductArr.length > 0)) {
            toastError("Please select products to proceed");
            return;
        }
        else if (!(selectedProductArr.every(el => el.gst > 0))) {
            toastError("Please add gst to all the products");
            return;
        }
        else if (!(selectedProductArr.every(el => (el.sellingPrice > 0)))) {
            toastError("Please add selling price to all the products");
            return;
        }
        else if (!(selectedProductArr.every(el => el.price > 0))) {
            toastError("Please add buying price to all the products");
            return;
        }
        else if (!(selectedProductArr.every(el => el.incomingStock > 0))) {
            toastError("Please add stock to all the products");
            return;
        }
        let obj = {
            productsArr: selectedProductArr.map(el => {
                let obj = {
                    ...el,
                    subTotal: (parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock),
                    totalPriceWithGst: (parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock),
                    totalPriceWithoutGst: (parseInt(el.price)) * parseInt(el?.incomingStock)
                }
                return obj
            }),
            sellerDetails: {
                name,
                email,
                phone,
                address,
                country,
                state,
                city,
                postalCode,
            },
            orderFor: "adminStockIn",
            subTotal: parseFloat(calculateTotal()),
            totalPriceWithGst: parseFloat(calculateGrandTotal()),
            totalPriceWithoutGst: parseFloat(calculateGrandWithoutGstTotal()),
            discountValue: 0,
            shippingCharge: shippingCharges,
            total: parseFloat(calculateGrandTotal()),
            roundOff: parseFloat(calculateRoundOff()),
        };

        dispatch(INTERNALORDERAdd(obj))

        // if (internalOrderObj && internalOrderObj?._id) {
        //   dispatch(INTERNALORDERUpdate(obj, prevObjId));
        // } else {
        //   dispatch(INTERNALORDERAdd(obj));
        // }
    };
    return (
        <section className="product-category">
            <div className="container-fluid p-0">
                <div className="row d-flex px-3">
                    <div className="col-12 col-md-12">
                        <DashboardBox>
                            <form className="form row">
                                <div className="col-12 col-md-6 pb-4">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h5 className="blue-1 m-0"> Products: {status}</h5>
                                    </div>
                                    <div style={{ marginTop: 5 }}>
                                        <label>
                                            Selected products from the list
                                            <span className="red">*</span>
                                        </label>
                                        <Select
                                            options={[
                                                ...productInternalArr.map((el) => {
                                                    return { ...el, label: el?.name, value: el?.name };
                                                }),
                                            ]}
                                            isMulti
                                            isDisabled
                                            placeholder="Select from Products to order"
                                            value={selectedProductArr}
                                            onChange={(e) => {
                                                console.log(e, "asd");
                                                handleProductSelect(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 pb-4">
                                    {/* <h5 className="blue-1 mt-1 ">Supplier Info</h5> */}
                                    {/* <div className="row">

                                        {supplierObj && (
                                                    <>   <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Name <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        value={supplierObj?.name}
                                                        onChange={(event) => setName(event.target.value)}
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Email <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={supplierObj?.email}
                                                        disabled
                                                        onChange={(e) => SetEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Phone <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={supplierObj?.phone}
                                                        disabled
                                                        onChange={(e) => SetPhone(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Address <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={supplierObj?.address}
                                                        disabled
                                                        onChange={(e) => SetAddress(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Country <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={country}
                                                        disabled
                                                        onChange={(e) => SetCountry(e.target.value)}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        State <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={state}
                                                        disabled
                                                        onChange={(e) => SetState(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        City <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={city}
                                                        disabled
                                                        onChange={(e) => SetCity(e.target.value)}
                                                    />
                                                </div> *
                                                <div className="col-12 col-md-6 mb-3 ">
                                                    <label>
                                                        Postal Code <span className="red">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={postalCode}
                                                        disabled
                                                        onChange={(e) => SetPostalCode(e.target.value)}
                                                    />
                                                </div>
                                                    </>
                                        )}
                                     
                                    </div> */}
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-9">
                                        <h5 className="blue-1 m-0">Selected Product List</h5>

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    {
                                                                    internalOrderObj?.type ==INTERNAL_ORDER_ORDER_TYPE.SELL && (
                                                    <th scope="col">Selling Price</th>

                                                                    )   
                                                        }               
                                                    <th scope="col">Buying Price</th>
                                                    <th scope="col">Gst</th>
                                                    <th scope="col">Stock</th>
                                                    <th scope="col">Total Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedProductArr &&
                                                    selectedProductArr.length > 0 &&
                                                    selectedProductArr.map((el, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{el.name}</th>
                                                                {
                                                                    internalOrderObj?.type ==INTERNAL_ORDER_ORDER_TYPE.SELL && (
                                                                        <td>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            disabled
                                                                            value={el?.sellingPrice}
                                                                            onChange={(e) =>
                                                                                handleValueChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                    "sellingPrice"
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    )
                                                                }
                                                              
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        disabled
                                                                        value={el?.buyingPrice}
                                                                        onChange={(e) =>
                                                                            handleValueChange(
                                                                                index,
                                                                                e.target.value,
                                                                                "price"
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        disabled
                                                                        onChange={(e) => {

                                                                            !(e.target.value > 100) &&

                                                                                handleValueChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                    "gst"
                                                                                )
                                                                        }
                                                                        }
                                                                        value={el?.igst}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        disabled
                                                                        onChange={(e) =>
                                                                            handleValueChange(
                                                                                index,
                                                                                e.target.value,
                                                                                "incomingStock"
                                                                            )
                                                                        }
                                                                        value={el?.quantity}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={((parseInt(el.buyingPrice) + percentage(el.igst, el.buyingPrice)) * parseInt(el?.quantity)).toFixed(2)}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-3" style={{ borderLeft: "solid 1px rgba(0,0,0,0.1)" }}>
                                        <h5 className="blue-1 m-0">Summary</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Total Items</th>
                                                    <th scope="col">{calculateTotalQuantity()}</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Sub Total </th>
                                                    <th scope="col">{calculateTotal()}</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Discount </th>
                                                    <th scope="col">0</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Total Shipping Charges</th>
                                                    <th>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={shippingCharges}
                                                            disabled
                                                            onChange={(e) =>
                                                                setShippingCharges(e.target.value)
                                                            }
                                                        />
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Grant Total </th>
                                                    <th scope="col">{calculateGrandTotal()}</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Round Off </th>
                                                    <th scope="col">{calculateRoundOff()}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </DashboardBox>
                    </div>
                </div>
            </div>
        </section>
    );
}
