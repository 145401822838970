import { Modal, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { downloadCSV } from "../Utility/CSV";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import tabClick from "../Utility/TabClick";
import { useDispatch, useSelector } from "react-redux";
import { usersGet } from "../../redux/actions/Users/users.actions";
import { updateUserKycStatus, updateUserStatus } from "../../services/users.service";
import {  getAllOrders } from "../../services/order.service";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import CustomerDetail from "./CustomerDetail";
import { EditModal } from "../Utility/Modal";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { rolesObj } from "../../utils/roles";
import { generalModelStatuses } from "../Utility/constants";
import { Link, useParams } from "react-router-dom";
function Orders() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [displayUsersArr, setDisplayUsersArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);
  const [activeUsersArr, setActiveUsersArr] = useState([]);
  const [inActiveUsersArr, setInActiveUsersArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const role = useSelector((state) => state.auth.role);
  const [selectedData, setSelectedData] = useState(null);
  const params = useParams()
  const [totalOrder, setTotalOrder] = useState(0);
  const [completeOrder, setcompleteOrder] = useState(0);
  const [rejectedOrder, setRejectedOrder] = useState(0);
 const [userId, setUserId] = useState("");
 const [ordersArr, setOrdersArr] = useState([]);
 const [displayOrderArr, setDisplayOrderArr] = useState([]);
 const [rejectOrderArr, setRejectOrderArr] = useState([]);
 const [deliveyObj, setDeliveyObj] = useState("");
 const [tablist, settablist] = useState([
      {
        tabName: "All Orders",
        name:"all",
        active: true,
      },
      {
        tabName: "Completed Orders",
        name:"complete",
        active: false,
      },
      {
        tabName: "Rejected Orders",
        name:"reject",
        active: false,
      },
    ])
    const sale_columns = [
      {
        name: "SL",
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row?.name,
      },
  
      {
        name: "Qty",
        cell: (row) => <p className="fs-14">{row.quantity}</p>,
      },
      {
        name: "Price",
        selector: (row) => `₹ ${row?.price}`,
      },
  
      {
        name: "Total",
        selector: (row) => parseInt(row?.quantity)*parseInt(row?.price),
      },
    ];
  const handleChangeActiveStatus = async (id, value) => {
    try {
      let { data: res } = await updateUserStatus(id, { isActive: value });
      if (res.message) {
        toastSuccess(res.message);
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }
  };
  useEffect(() => {
    if(params?.id){
      setUserId(params?.id);
      getUserOrder(params?.id);
      getRejectedOrder(params?.id)
    }
  }, [params])

  useEffect(() => {
      let activeTab = tablist.find((el) => el.active == true);
      if(activeTab && ordersArr){
        if(activeTab && activeTab.name){
          if(activeTab.name == 'all'){
            setDisplayOrderArr([...ordersArr,...rejectOrderArr])
          }
          if(activeTab.name == 'complete'){
            setDisplayOrderArr(ordersArr)

          }
          if(activeTab.name == 'reject'){
            setDisplayOrderArr(rejectOrderArr)
          }
        }
      }
  }, [tablist])


  useEffect(() => {
    if(rejectedOrder || completeOrder){
      setTotalOrder(parseInt(completeOrder) + parseInt(rejectedOrder))
    }
  }, [rejectedOrder,completeOrder])
  const getUserOrder = async (userID) => {
    try {

        let query = '';
        query += 'deliveryBoyId='+userID+'&reject=true'; 

        let { data: res } = await getAllOrders(query);
        if (res && res?.success) {
            console.log(res.data.length,"orders")
            setcompleteOrder(res.data.length)
            setOrdersArr(res.data?.filter((el) => el.deliveryBoyStatus?.currentStatus !== generalModelStatuses.REJECT))
            setDisplayOrderArr(res.data?.filter((el) => el.deliveryBoyStatus?.currentStatus !== generalModelStatuses.REJECT))
        }
        return

    } catch (error) {
        console.error(error)
        toastError(error)
    }
}


const getRejectedOrder = async (userID) => {
  try {

      let query = '';
          query += 'deliveryId='+userID+'&reject=true'; 

      let { data: res } = await getAllOrders(query);
      if (res && res?.success) {
        console.log(res.data.length,"orders")
        setRejectedOrder(res.data.length)
          setRejectOrderArr(res.data)
      }
      return

  } catch (error) {
      console.error(error)
      toastError(error)
  }
}


  const handleModalSet = (e, row) => {
    e.preventDefault();
    setModalBox(true);
    setDeliveyObj(row)
  };
  const [users_columns, setUsers_columns] = useState([
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },

    {
      name: "NAME",
      selector: (row) =>  `${row?.userObj?.name ? row?.userObj?.name : 'User'}`,
      width: "10%",
    },
    {
      name: "EMAIL",
      selector: (row) =>  `${row?.userObj?.email ? row?.userObj?.email : 'NA'}`,
      width: "15%",
    },
    {
      name: "PHONE",
      selector: (row) => (row?.userObj.phone || row.userObj.phone != "") ? row.userObj.phone : "NA",
      width: "10%",
    },

    {
      name: "Total",
      selector: (row) => row.totalPrice,
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) => row.deliveryBoyStatus?.currentStatus,
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <CustomButton btntype="button"  ClickEvent={(e) => handleModalSet(e, row)} isBtn iconName="fa-solid fa-check" btnName="View" />
        </>
      ),
      width: "15%",
    }
  ]);

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid">
          <div className="row">

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Total Orders</h5>
                    <h4 className="text-dark mb-0">{totalOrder}</h4>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Completed Orders</h5>
                    <h4 className="text-dark mb-0">{completeOrder}</h4>
                  </DashboardBox>
                </div>

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Rejected Orders</h5>
                    <h4 className="text-dark mb-0">{rejectedOrder}</h4>
                  </DashboardBox>
                </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
            <ul className="nav nav-pills dashboard-pills justify-content-end" id="pills-tab" role="tablist">
              {tablist.map((item, i) => {
                return (
                  <li key={i}>
                    <CustomButton
                      navPills
                      btnName={item.tabName}
                      pillActive={item.active ? true : false}
                      path={item.path}
                      extraClass={item.extraClass}
                      ClickEvent={() => {
                        tabClick(i, tablist, settablist);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
            {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="Create Delivery" path="/Delivery/AddDeliveryUser" /> */}
          </div>
        <div className="container-fluid p-0">
        
          <DashboardTable>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h5 className="blue-1 m-0"> Orders</h5>
              <div className="d-flex align-items-center gap-3">
                <SearchBox extraClass="bg-white" />
                {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
              </div>
            </div>
            <Modal open={ModalBox} onClose={() => setModalBox(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modal-box">
                  <div className="modal-container" style={{maxWidth:'1000px'}} >
                    <div className="modal-header">
                      <h5>Delivery Details</h5>
                      <CustomButton
                        isBtn
                        btntype="button"
                        iconName="ion-close-circled text-white"
                        changeClass="border-0 bg-transparent rounded-circle modal-close"
                        ClickEvent={(e) => {
                          e.preventDefault();
                          setModalBox(false);
                        }}
                      />
                    </div>
                    <div className="modal-body">
                    <main>
                      <section className="product-category" >
                        <div className="container-fluid p-0" style={{ height: "60vh", overflowY: "auto", maxWidth: "80vw" }}>
                          <DashboardBox className="mb-5" >
                            <div className="row">
                              <div className={`col-12 col-md-12`} style={{ overflowWrap: "break-word" }}>
                                {
                                  deliveyObj && deliveyObj && (
                                    <div className="customer-profile">
                                    {/* <h6 className="blue-1 text-capitalize my-3">{deliveyObj?.userObj?.name ? deliveyObj?.userObj?.name:'User'}</h6> */}
                                    <ul className="blue-1 fs-14">
                                      <li><span className="fw-600">Name <span>:</span></span>{deliveyObj?.userObj?.name ? deliveyObj?.userObj?.name:'User'}</li>   
                                      <li><span className="fw-600">Email <span>:</span></span>{deliveyObj?.userObj?.email ? deliveyObj?.userObj?.email:'NA'}</li>   
                                      <li><span className="fw-600">Phone <span>:</span></span>{deliveyObj?.userObj?.phone ? deliveyObj?.userObj?.phone:'NA'}</li>   
                                      <li><span className="fw-600">From <span>:</span></span>{deliveyObj?.storeObj?.storeAddressObj?.addressName ? deliveyObj?.storeObj?.storeAddressObj?.addressName:'NA'}</li>   
                                      <li><span className="fw-600">To <span>:</span></span>{deliveyObj?.userAddress?.addressName ? deliveyObj?.userAddress?.addressName:'NA'}</li>   
                                      <li><span className="fw-600">Total <span>:</span></span>{deliveyObj?.totalPrice}</li>   
                                      <li><span className="fw-600">Status <span>:</span></span>{deliveyObj?.deliveryBoyStatus.currentStatus }</li>   
                                    </ul>
                                    <h6 className="blue-1 text-capitalize my-3">Product Details</h6>
                                    <DashboardTable >
                                        <DataTable columns={sale_columns} data={deliveyObj?.items}  />
                                </DashboardTable>
                                  </div>
                                  )
                                }
                               
                              </div>
                            </div>
                          </DashboardBox>
                        </div>
                      </section>
                    </main>
                    </div>
                  </div>
                </Box>
              </Modal>
            <DataTable columns={users_columns} data={displayOrderArr} pagination />
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default Orders;
