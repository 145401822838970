import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { getCurrentStocks, getSalesReport } from "../../services/index.service";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import moment from "moment";


function SaleReport() {
    const dispatch = useDispatch();

    const [stockArr, setStockArr] = useState([]);

    const handleGetProducts = async () => {
        try {
            const { data: res } = await getSalesReport();
            if (res) {
                console.log(res.data)
                setStockArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }

    }
    useEffect(() => {
        handleGetProducts()
    }, []);







    const product_sale_columns = [

        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row?.items?.name,
        },

        {
            name: "Quantity",
            selector: (row) => row?.items?.quantity,
        },


        {
            name: "Price",
            selector: (row) => row?.items?.price,
        },

        {
            name: "Date",
            selector: (row) => moment(row?.createdAt).format("DD-MM-YYYY"),
        },


    ];

    return (
        <main>

            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1">Sale  Report</h5>
                            </div>

                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={stockArr && stockArr.length > 0 ? stockArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default SaleReport;
