import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllOrders } from "../../../services/order.service";
import { rolesObj } from "../../../utils/roles";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import ActionIcon from "../../Utility/ActionIcon";

function PendingOrder({ name }) {
  const [orders, setOrders] = useState([]);
  const role = useSelector((state) => state?.auth?.role);
  const user = useSelector((state) => state?.auth?.user);

  const getOrder = async () => {
    try {
      let  querty = ''
        if(role == rolesObj.STORE){
          querty += `orderedToId=${user._id}`;
        }
      const { data: res } = await getAllOrders(querty);
      if (res) {
        setOrders(res.data.filter((el) => el.status != "CANCELLED"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const PendingOrder_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
    },
    {
      name: "Order ID",
      selector: (row) => row?._id,
    },
    {
      name: "Phone",
      selector: (row) => row?.userObj?.phone,
    },
    {
      name: "Total QTY",
      selector: (row) => row?.totalItems,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalPrice,
    },
    {
      name: "Order Status",
      button: true,
      width: "10%",
      cell: (row) => <CustomButton redBtn={row?.status == "CANCELLED"} greenBtn={row?.status != "CANCELLED"} btnName={row?.status} />,
    },
    // {
    //   name: "Is Paid",
    //   button: true,
    //   width: "10%",
    //   cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
    // },
    {
      name: "Action",
      name: "Action",
      cell: (row) => <CustomButton btnName={"View"} path={`/Order/Sale-Detail?orderId=${row?._id}`} isLink />,

    },
  ];

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">Orders</h5>
        <SearchBox extraClass="bg-white" />
      </div>
      <DataTable columns={PendingOrder_columns} data={orders} pagination />
    </DashboardTable>
  );
}

export default PendingOrder;
