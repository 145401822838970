import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthorizedRoutes from "./AuthorizedRoutes";
import { useSelector } from "react-redux";
import UnauthorizedRoutes from "./UnauthorizedRoutes";
import { axiosApiInstance } from "../App";
import { logoutUser } from "../redux/actions/auth/auth.actions";
import { getRefreshToken, refreshTokenApi, updateToken } from "../services/users.service";
export default function RootRouter() {
  const authObj = useSelector((state) => state.auth);
  const getToken = () => {
    let token = localStorage.getItem("token");
    return token;
  };


  useMemo(() => {
    let token = getToken();
    console.log(token, "TOKENffff");
    axiosApiInstance.interceptors.request.use(
      async (config) => {

        if (token) {
          config.headers["authorization"] = "Bearer " + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        console.log("INterceptor erroreeefffffffffffffffffffffffffffffffe");
        console.log(error,"df");
        Promise.reject(error);
      }
    );
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        let originalConfig = err.config;

          // Access Token was expired
        console.log(originalConfig._retry,"originalConfigoriginalConfig1")

          if (err.response.status == 401 && !originalConfig._retry && originalConfig._retry !=true) {
            originalConfig._retry = true;
            console.log(originalConfig,"originalConfigoriginalConfig13333333")
            return axiosApiInstance(originalConfig);
            // try {
            //   let  obj = {
            //     refreshToken: getRefreshToken()
            //   }
            //   const rs = await refreshTokenApi(obj);
            //   const { refreshToken,token } = rs.data;
            //   updateToken(refreshToken,token);
            //   return axiosApiInstance(originalConfig);
            // } catch (_error) {
            //   return Promise.reject(_error);
            // }
          }
    
        return Promise.reject(err);
      }
    );
  }, []);
  return <Router>{authObj?.isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</Router>;
}