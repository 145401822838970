import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../../Utility/FileUpload";
import { addHomePage,getAll } from "../../../services/HomePage.service";
import { useSelector, useDispatch } from "react-redux";
import { PRODUCTGet } from "../../../redux/actions/Product/Product.actions";
import { CATEGORYGet } from "../../../redux/actions/Category/Category.actions";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";

function HomePage() {
  const dispatch = useDispatch();
    const productArr = useSelector((state) => state.product.products);
    const categoryArr = useSelector((state) => state.category.categories);
    const [displayProductArr, setDisplayProductArr] = useState([]);
    const [displayCategoryArr, setDisplayCategoryArr] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [trendingProduct, setTrendingProduct] = useState([]);
  const [seasonProduct, setSeasonProduct] = useState([]);
  const [otherProdcuts, setOtherProdcuts] = useState([]);
  const [homepage, setHomepage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        categoryArr:displayCategoryArr,
        trendingProduct,
        seasonProduct,
        otherProdcuts,
      };
      const { data: res } = await addHomePage(obj);
      if (res) {
        toastSuccess(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (productArr && productArr.length > 0) {
      setDisplayProductArr([...productArr.map(el => {
        el.checked = false
        return el
      })])
    }
  }, [productArr])

const handleGetHomepage =  async() => {
  try {
    let {data:res} = await getAll();
    if(res.data && res.data?.length > 0){
      setHomepage(res.data[0]);
    }
  } catch (error) {
    console.error(error);

  }
}

  useEffect(() => {
    dispatch(PRODUCTGet());
    dispatch(CATEGORYGet('level=1'));
    handleGetHomepage();
  }, []);

  useEffect(() => {
    if(homepage){

      setDisplayCategoryArr(homepage?.categoryArr.map(el => {
        el.category.value = el.category._id;
        el.category.label = el.category.name;
        return el
      }))
      setTrendingProduct(homepage?.trendingProduct.map(el => {
        el.value = el._id
        el.label = el.name;
        return el
      }))
      setSeasonProduct(homepage?.seasonProduct.map(el => {
        el.value = el._id
        el.label = el.name;
        return el
      }))
      setOtherProdcuts(homepage?.otherProdcuts.map(el => {
        el.value = el._id
        el.label = el.name;
        return el
      }))
    }
  }, [homepage]);


  useEffect(() => {
    if (categoryArr?.length) {
      setCategoriesArr(categoryArr)
    }
  }, [categoryArr])

  const handleMultipleCategoryAdd = () => {
    setDisplayCategoryArr([...displayCategoryArr, { position: "",category:"" }]);
  };
  const handleMultipleCategoryRemove = (ind) => {
      setDisplayCategoryArr([...displayCategoryArr.filter((el, index) => index != ind)]);
  };
  const handleSetMultipleCategoryQuestion = (value, index) => {
    let tempArr = displayCategoryArr;
    let postemp = tempArr.find((el) => el.category?.value == value._id)
    if(postemp){
      toastError("Category already selected")
      return
    }
    console.log(value,"displayCategoryArr",displayCategoryArr)
    tempArr[index].category = value;
    setDisplayCategoryArr([...tempArr]);
  };
  const handleSetMultipleCategoryAnswer = (value, index) => {
    
    console.log("value",value)
    let tempArr = displayCategoryArr;
    let postemp = tempArr.find((el) => el.position == value)
    if(postemp){
      toastError("Position already selected")
      return
    }
    tempArr[index].position = value;
    setDisplayCategoryArr([...tempArr]);
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">Home Page</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Home Page</h5>
                    <div className="col-12">
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                        Products <span className="red">*</span>
                        </label>
                        {displayProductArr && displayProductArr.length > 0 && (
                          <Select
                            isMulti
                            onChange={(val) => setTrendingProduct(val)}
                            value={trendingProduct}
                            isOptionDisabled={() => trendingProduct?.length >= 5}
                            options={
                              displayProductArr && displayProductArr.length > 0
                                ? displayProductArr.map((el) => ({
                                    ...el,
                                    label: el.name,
                                    value: el._id,
                                  }))
                                : []
                            }
                          />
                        )}
                      </div>
                      <div className="col-12 col-md-12 mb-3">

                      <label className="me-3">Home Page Category</label>

                    <CustomButton
                            isBtn
                            noIcon={true}
                            btntype="button"
                            iconName="fa-solid fa-check"
                            btnName="Add"
                            ClickEvent={() => handleMultipleCategoryAdd()}
                          />
                      {/* <div className="row d-flex justify-content-end">
                        <div className="col-3 d-flex justify-content-between">
                       
                          <CustomButton
                            isBtn
                            noIcon={true}
                            btntype="button"
                            iconName="fa-solid fa-check"
                            btnName="Remove"
                            ClickEvent={() => handleMultipleFaqRemove()}
                          />
                        </div>
                      </div> */}
                 
                    <div className="row">
                      {displayCategoryArr &&
                        displayCategoryArr.length > 0 &&
                        displayCategoryArr.map((el, index) => {
                          return (
                            <div key={index} className="row">
                              <div className="col-md-6">
                              <label   htmlFor="">Category {index + 1}</label>   
                              {categoriesArr && categoriesArr.length > 0 && (
                                    <Select
                                        onChange={(val) => handleSetMultipleCategoryQuestion(val,index)}
                                        value={el.category}
                                        options={
                                        categoriesArr && categoriesArr.length > 0
                                            ? categoriesArr.map((el) => ({
                                                ...el,
                                                label: el.name,
                                                value: el._id,
                                            }))
                                            : []
                                        }
                                    />
                                )}
            
                              </div>
                           
                              <div className="col-md-4">
                                  <label className="blue-1 fs-12">
                                  Display at Position <span className="red">*</span>
                                </label>
                              <select className="form-control" value={el.position} onChange={(e)=>handleSetMultipleCategoryAnswer(e.target.value,index)} >
                                <option value>Please Select Option</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                
                              </select>
                              </div>
                              <div className="col-md-2 mb-0">
                              <button type="button"   onClick={()=>handleMultipleCategoryRemove(index)} className="btn   btn-danger btn-sm"><i className="fa fa-times" ></i></button>
                                </div>
                            </div>
                          );
                        })}
                    </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label>
                           Best For Season <span className="red">*</span>
                        </label>
                        {categoriesArr && categoriesArr.length > 0 && (
                          <Select
                            isMulti
                            onChange={(val) => setSeasonProduct(val)}
                            value={seasonProduct}
                            isOptionDisabled={() => seasonProduct?.length >= 5}
                            options={
                              categoriesArr && categoriesArr.length > 0
                                ? categoriesArr.map((el) => ({
                                    ...el,
                                    label: el.name,
                                    value: el._id,
                                  }))
                                : []
                            }
                          />
                        )}
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label>
                           Other Produts <span className="red">*</span>
                        </label>
                        {categoriesArr && categoriesArr.length > 0 && (
                          <Select
                            isMulti
                            onChange={(val) => setOtherProdcuts(val)}
                            value={otherProdcuts}
                            isOptionDisabled={() => otherProdcuts?.length >= 5}
                            options={
                              categoriesArr && categoriesArr.length > 0
                                ? categoriesArr.map((el) => ({
                                    ...el,
                                    label: el.name,
                                    value: el._id,
                                  }))
                                : []
                            }
                          />
                        )}
                      </div>
                    </div>
                   
                  </div>
                  <div className="col-12 mt-4">
                    <CustomButton ClickEvent={(e) => handleSubmit(e)} isBtn iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </DashboardBox>
              </div>
             
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default HomePage;
