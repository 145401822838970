import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { BANNERGet, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { generateFilePath } from "../../Utility/utils";
import { deleteSaleById, getSale } from "../../../services/sale.service";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";

function Sale() {
  const dispatch = useDispatch();

  // const bannerArr = useSelector((state) => state.banner.banners);
  const [saleArr, setSaleArr] = useState([]);

  const handleGet = async (e) => {
    try {
      let { data: res } = await getSale()
      if (res.data) {
        setSaleArr(res.data)
        // toastSuccess(res.message)
      }
      // if (isUpdateBanner) {
      // dispatch(BANNERUpdate(obj, selectedBannerId));
      // } else {
      // dispatch(BANNERAdd(obj));
      // }
    }
    catch (err) {
      toastError(err)
    }
  };

  
  const handleCategoryDelete = async (row) => {
    try {
      let { data: res } = await deleteSaleById(row?._id)
      if (res.message) {
        toastSuccess(res.message)
      }
     
    }
    catch (err) {
      toastError(err)
    }
}

  useEffect(() => {
    handleGet()
  }, []);

  const handleEdit = (row) => {
    dispatch(SetBANNERObj(row));
  };
  const brand_columns = [
    {
      name: "Image",
      grow: 0,
      width: "20%",
      cell: (row) => <img height="84px" width="56px" alt={row.name} src={generateFilePath(row.imageUrl)} />,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => <ActionIcon Uniquekey={row._id} remove edit deletePath="/Sales" onDeleteClick={() => handleCategoryDelete(row)} isRedirected={true} onEditClick={() => handleEdit(row)} editPath="/Sales/Sales-Create" />,
  },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Sale</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW LOGO" path="/Sales/Sales-Create" small roundedPill />
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={brand_columns} data={saleArr && saleArr.length > 0 ? saleArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Sale;
