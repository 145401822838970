import React, { useEffect, useState } from "react";
import { businessNatureObj, businessTypeObj, rolesObj } from "../../utils/roles";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import Select from "react-select";

import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addUser } from "../../services/users.service";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../Utility/FileUpload";
import { STATEGET } from "../../redux/actions/State/States.actions";
import { CITYGET } from "../../redux/actions/City/City.actions";
import { AREAGET } from "../../redux/actions/Area/Area.actions";
import { generalModelStatuses } from "../Utility/constants";
function AddDeliveryUser() {
  const dispatch = useDispatch()

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(false);
  const [role, setRole] = useState(rolesObj.BUYER);
  const user = useSelector((state) => state?.auth?.user);

  const [rolesArr, setRolesArr] = useState([{ value: "SUBADMIN", label: "SUBADMIN" }, { value: "WAREHOUSE", label: "WAREHOUSE" }, 
  { value: "STORE", label: "STORE" },
 {value: 'OFFLINESTORE',label: "OFFLINESTORE"},
 {value:"DELIVERY" ,label: "DELIVERY"},
 {value: 'DELIVERYTEAM',label: "DELIVERYTEAM"},

]);


  const [stateId, setStateId] = useState("");
  const [stateObj, setStateObj] = useState({});
  const [cityArr, setCityArr] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [areaArr, setAreaArr] = useState([]);
  const [cityId, setCityId] = useState("");
  const [citiesObj, setCitiesObj] = useState({});
  const [areaId, setAreaId] = useState("");
  const [areasObj, setAreasObj] = useState({});
  const [pincode, setPincode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [googleAddress, setgoogleAddress] = useState("");
  const [pan, setPan] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [panFile, setPanFile] = useState("");
  const [aadharFile, setAadharFile] = useState("");
  const [profile, setProfile] = useState("");
  const statesArr = useSelector((state) => state.states.states);
  const citiesArr = useSelector((state) => state.city.cities);
  const areasArr = useSelector((state) => state.area.areas);
  const [permissionsArr, setPermissionsArr] = useState([
    {
      name: "Category",
      permissionsObj: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },

    },
    {
      name: "Products",
      permissionsObj: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
    },
    {
      name: "Homepage customizations",
      permissionsObj: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
    }
  ]);


  const handleSubmit = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory")
        return
      }
      else if (email == "") {
        toastError("Email is mandatory")
        return
      }
      else if (phone == "") {
        toastError("Phone is mandatory")
        return
      }
      else if (phone.length != 10) {
        toastError("Invalid Phone number")
        return
      }

      
      if (role == rolesObj.SUBADMIN) {
        // if (natureofBusiness == "") {
        //   toastError("Nature of Business is mandatory")
        //   return
        // }
        // else if (businessType == "") {
        //   toastError("Business Type is mandatory")
        //   return
        // }
      }

      console.log(role,"deliveryteamdeliveryteamdeliveryteamdeliveryteamdeliveryteamdeliveryteam")
  
        if (stateId == "" || !stateId) {
          toastError("Please Select a state")
        }
        if (cityId == "" || !cityId) {
          toastError("Please Select a City")
        }
        if (areaId == "" || !areaId) {
          toastError("Please Select a Area")
        }

        if (pincode == "" || !pincode) {
          toastError("Please Enter pincode")
        }
   
    

      if (pan == "" || pan.length !=10) {
        toastError("Please fill valid PAN No.")
        return
      }
    if (aadhar == "" || aadhar.length !=12) {
        toastError("Please fill valid Aadhar No.")
        return
      }

      if (aadharFile == "") {
        toastError("Please upload aadhar photo.")
        return
      }
      if (panFile == "") {
        toastError("Please upload pan photo.")
        return
      }
      let obj = {
        name,
        email,
        phone,
        isActive: status,
        role:rolesObj.DELIVERY,
        stateId,
        cityId,
        areaId,
        addressLine2,
        addressLine1,
        googleAddress,
        pincode,
        pan,
        aadhar,
        aadharFile,
        panFile,
        profile,
        isKyc:generalModelStatuses.APPROVED
      }

      if (role == rolesObj.OFFLINESTORE) {
        // obj.permissionsArr = permissionsArr.map(el => {
        //   el.permissionName = el.name;
        //   return el
        obj.wareHouseId = user?._id
        }
      // }
      let { data: res } = await addUser(obj);
      if (res.message) {
        toastSuccess(res.message)
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleCheckPermission = (index, indexX) => {
    let tempPermissionArr = permissionsArr;
    console.log(tempPermissionArr[index].permissionsObj[indexX], "tempPermissionArr[index].innerPermissionsArr[indexX]")
    tempPermissionArr[index].permissionsObj[indexX] = !tempPermissionArr[index].permissionsObj[indexX]
    setPermissionsArr([...tempPermissionArr])
  }



  useEffect(() => {


    if (statesArr) {
      setStateArr(statesArr)
    }
    if (citiesArr) {
      setCityArr(citiesArr)
    }
    if (areasArr) {
      setAreaArr(areasArr)
    }
  }, [statesArr, citiesArr, areasArr]);



  useEffect(() => {
    dispatch(STATEGET());
    dispatch(CITYGET());
    dispatch(AREAGET());
  }, [])



  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Add New Delivery</h5>
          <DashboardBox>
            <form className="form row">
              <h5 className="blue-1 mb-4">Basic Info</h5>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input
                  name="first_name"
                  className="form-control"
                  type="text"
                  required=""
                  onChange={(e) => setName(e.target.value)} value={name}
                />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>Phone</label>
                <input
                  className="form-control"
                  type="number"
                  maxLength={10}
                  onChange={(e) => setPhone(e.target.value)} value={phone}
                />
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label>
                  Email Address 
                  <span className="red">*</span>
                </label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} name="text" className="form-control" type="email" />
              </div>
               
            
              {/* <div className="col-12 col-md-4 mb-3">
                <label>

                  PASSWORD
                  <span className="red">*</span>
                </label>
                <input onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" type="text" />
              </div> */}

           
            
                <h5> Address</h5>
                  <div className="col-12 col-md-6">
                    <label className="blue-1 fs-12">
                      Address Line 1 <span className="red">*</span>
                    </label>
                        <input type="text" className="form-control"  value={addressLine1} onChange={(e)=>{setAddressLine1(e.target.value)}}/>
                  </div>

                  <div className="col-12 col-md-6">
                    <label className="blue-1 fs-12">
                      Address Line 2 <span className="red">*</span>
                    </label>
                        <input type="text" className="form-control"  value={addressLine2} onChange={(e)=>{setAddressLine2(e.target.value)}}/>
                  </div>

                 
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      State <span className="red">*</span>
                    </label>
                    <Select
                      options={stateArr && stateArr.length > 0 && stateArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={stateId} value={stateObj}
                      onChange={(e) => {
                        console.log(e, "asd")
                        setStateId(e.value);
                        setStateObj(e);
                        dispatch(CITYGET(`stateId=${e.value}`));
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      City <span className="red">*</span>
                    </label>
                    <Select
                      options={cityArr && cityArr.length && cityArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={cityId} value={citiesObj}
                      onChange={(e) => {
                        console.log(e, "asd")
                        setCityId(e.value);
                        setCitiesObj(e);
                        dispatch(AREAGET(`cityId=${e.value}`));

                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      Area <span className="red">*</span>
                    </label>
                    <Select
                      options={areaArr && areaArr.length && areaArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={areaId} value={areasObj}
                      onChange={(e) => {
                        console.log(e, "area")
                        setAreaId(e.value);
                        setAreasObj(e);

                      }}
                    />
                  </div>

                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      Pincode <span className="red">*</span>
                    </label>
                        <input type="text" className="form-control"  value={pincode} onChange={(e)=>{setPincode(e.target.value)}}/>
                  </div>
                    <h5> KYC</h5>
                       
                          <div className="col-12  col-md-6 mb-3">
                            <label>
                              PAN No. <span className="red">*</span>
                            </label>
                            <input value={pan} onChange={(e) => setPan(e.target.value)} type="text" className="form-control" />
                            </div>
                            <div className="col-12  col-md-6 mb-3">
                            <label>
                               Aadhar No. <span className="red">*</span>
                            </label>
                            <input value={aadhar} onChange={(e) => setAadhar(e.target.value)} type="text" maxLength="12" className="form-control" />
                          </div>
                          <div className="col-12  col-md-6 mb-3">
                            <label>
                               Upload Pan Card<span className="red">*</span>
                            </label>
                            <FileUpload acceptImage={true} onFileChange={(value) => setPanFile(value)} />
      
                          </div>
                          <div className="col-12  col-md-6 mb-3">
                            <label>
                               Upload Aadhar Card<span className="red">*</span>
                            </label>
                            <FileUpload acceptImage={true} onFileChange={(value) => setAadharFile(value)} />
      
                          </div>
                          <div className="col-12  col-md-6 mb-3">
                            <label>
                               Upload Profile<span className="red">*</span>
                            </label>
                            <FileUpload acceptImage={true} onFileChange={(value) => setProfile(value)} />
      
                          </div>

              <div className="col-12 mb-3">
                <label className="blue-1 fs-12">STATUS</label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="category-status"
                      // value="true"
                      checked={status == true}
                      onChange={(e) => setStatus(true)}
                      id="active-customer"
                    />
                    <label
                      className="form-check-label fs-14"
                      htmlFor="active-customer"
                    >
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="category-status"
                      value="option2"
                      checked={status == false}
                      onChange={(e) => setStatus(false)}
                      id="inActive-customer"
                    />
                    <label
                      className="form-check-label fs-14"
                      htmlFor="inActive-customer"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
              </div>

              {/* {
                role == rolesObj.SUBADMIN && permissionsArr && permissionsArr.length > 0 && permissionsArr.map((el, index) => {
                  return (
                    <div key={index} className="col-12 col-md-12 mb-3">
                      <label>
                        {el.name}
                      </label>
                      {
                        el.permissionsObj &&
                        <>
                          <div className="col-12 col-md-12 mb-3">
                            <input checked={el.permissionsObj["CREATE"]} onChange={() => handleCheckPermission(index, "CREATE")} type="checkbox" />
                            <span style={{ marginLeft: 15 }}>
                              Create
                            </span>
                          </div>
                          <div className="col-12 col-md-12 mb-3">
                            <input checked={el.permissionsObj["READ"]} onChange={() => handleCheckPermission(index, "READ")} type="checkbox" />
                            <span style={{ marginLeft: 15 }}>
                              Read
                            </span>
                          </div>
                          <div className="col-12 col-md-12 mb-3">
                            <input checked={el.permissionsObj["UPDATE"]} onChange={() => handleCheckPermission(index, "UPDATE")} type="checkbox" />
                            <span style={{ marginLeft: 15 }}>
                              Update
                            </span>
                          </div>
                          <div className="col-12 col-md-12 mb-3">
                            <input checked={el.permissionsObj["DELETE"]} onChange={() => handleCheckPermission(index, "DELETE")} type="checkbox" />
                            <span style={{ marginLeft: 15 }}>
                              Delete
                            </span>
                          </div>
                        </>
                      }
                    </div>
                  )
                })
              } */}



            </form>
            <div className="col-12 mt-2 text-center">
              <CustomButton
                ClickEvent={() => handleSubmit()}
                isBtn
                iconName="fa-solid fa-check"
                btnName="Create"
              />
            </div>
          </DashboardBox>
        </div>
      </section>
    </main >
  );
}

export default AddDeliveryUser;
