export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
  DELIVERED: "DELIVERED",
  REJECTED: "REJECTED",
  APPLIED: "APPLIED",
  ORDERED: "ORDERED",
  PROCESSED: "PROCESSED",
  DISPATCHED: "DISPATCHED",
  CANCELLED: "CANCELLED",

};

export const INTERNAL_ORDER_ORDER_FOR = {
  ADMIN_STOCK_IN:"adminStockIn",
  WAREHOUSE_STOCK_IN:"wareHouseStockIn",
  STORE_STOCK_IN:"storeStockIn",
};


export const INTERNAL_ORDER_ORDER_TYPE = {
  BUY:"BUY",
  SELL:"SELL",
};
