import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as SUPPLIER from "../../actions/Supplier/Supplier.actions";

const initialState = {
  suppliers: null,
  suppliersObj: null,
  loading: false,
  error: null,
};

export const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER.SUPPLIER_ADD:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIER.SUPPLIER_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case SUPPLIER.SUPPLIER_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIER.GET_ALL_SUPPLIER:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIER.GET_ALL_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        suppliers: action.payload.data,
      };
    case SUPPLIER.GET_ALL_SUPPLIER_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIER.DELETE_SUPPLIER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIER.DELETE_SUPPLIER_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUPPLIER.DELETE_SUPPLIER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIER.UPDATE_SUPPLIER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIER.UPDATE_SUPPLIER_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUPPLIER.UPDATE_SUPPLIER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIER.SET_SUPPLIER_OBJ:
      return {
        ...state,
        loading: true,
      };
    case SUPPLIER.SET_SUPPLIER_OBJ_SUCCESS:
      // toastSuccess(action.payload.message);
      return {
        ...state,
        suppliersObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SUPPLIER.SET_SUPPLIER_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
