// Faq
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/HomePage";

export const addHomePage = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getAll = (formData) => {
    return axiosApiInstance.get(serverUrl + "/getHomePage", formData);
};