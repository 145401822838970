import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as SUBSCRIPTION from "../../actions/Subscription/Subscription.actions";

const initialSubscription = {
  subscriptions: null,
  subscriptionsObj: null,
  loading: false,
  error: null,
};

export const subscriptionReducer = (subscription = initialSubscription, action) => {
  switch (action.type) {
    case SUBSCRIPTION.SUBSCRIPTION_ADD:
      return {
        ...subscription,
        loading: true,
      };
    case SUBSCRIPTION.SUBSCRIPTION_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...subscription,
        loading: false,
      };
    case SUBSCRIPTION.SUBSCRIPTION_ADD_FAIL:
      toastError(action.payload);
      return {
        ...subscription,
        loading: false,
        error: action.payload,
      };

    case SUBSCRIPTION.GET_ALL_SUBSCRIPTIONS:
      return {
        ...subscription,
        loading: true,
      };
    case SUBSCRIPTION.GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...subscription,
        loading: false,
        error: null,
        subscriptions: action.payload.data,
      };
    case SUBSCRIPTION.GET_ALL_SUBSCRIPTIONS_FAIL:
      toastError(action.payload);
      return {
        ...subscription,
        loading: false,
        error: action.payload,
      };

    case SUBSCRIPTION.DELETE_SUBSCRIPTION_BY_ID:
      return {
        ...subscription,
        loading: true,
      };
    case SUBSCRIPTION.DELETE_SUBSCRIPTION_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...subscription,
        loading: false,
        error: null,
      };
    case SUBSCRIPTION.DELETE_SUBSCRIPTION_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...subscription,
        loading: false,
        error: action.payload,
      };

    case SUBSCRIPTION.UPDATE_SUBSCRIPTION_BY_ID:
      return {
        ...subscription,
        loading: true,
      };
    case SUBSCRIPTION.UPDATE_SUBSCRIPTION_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...subscription,
        loading: false,
        error: null,
      };
    case SUBSCRIPTION.UPDATE_SUBSCRIPTION_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...subscription,
        loading: false,
        error: action.payload,
      };

    case SUBSCRIPTION.SET_SUBSCRIPTION_OBJ:
      return {
        ...subscription,
        loading: true,
      };
    case SUBSCRIPTION.SET_SUBSCRIPTION_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...subscription,
        subscriptionsObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SUBSCRIPTION.SET_SUBSCRIPTION_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...subscription,
        loading: false,
        error: action.payload,
      };

    default:
      return subscription;
  }
};
