// Sale
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/sale";

export const addSale = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getSale = (query) => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteSaleById = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateSaleById = (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
