import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { SUPPLIERADD, SUPPLIERUPDATE, SETSUPPLIEROBJ } from "../../../redux/actions/Supplier/Supplier.actions";
import { STATEGET } from "../../../redux/actions/State/States.actions";
import { CITYGET } from "../../../redux/actions/City/City.actions";
import { AREAGET } from "../../../redux/actions/Area/Area.actions";
import { toastError } from "../../Utility/ToastUtils";
function AddSupplier({ makeChange }) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);
    const supplierObj = useSelector((state) => state.supplier.suppliersObj);

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [stateId, setStateId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const [cityArr, setCityArr] = useState([]);
    const [stateArr, setStateArr] = useState([]);
    const [areaArr, setAreaArr] = useState([]);
    const [cityId, setCityId] = useState("");
    const [citiesObj, setCitiesObj] = useState({});
    const [areaId, setAreaId] = useState("");
    const [areasObj, setAreasObj] = useState({});
    const [pincode, setPincode] = useState("");
    const [address, setAddress] = useState("");
    const statesArr = useSelector((state) => state.states.states);
    const citiesArr = useSelector((state) => state.city.cities);
    const areasArr = useSelector((state) => state.area.areas);
    const handleAddCategory = () => {

        if (name == "") {
            toastError("Name is mandatory")
            return
          }
          else if (email == "") {
            toastError("Email is mandatory")
            return
          }
          else if (phone == "") {
            toastError("Phone is mandatory")
            return
          }
          else if (phone.length != 10) {
            toastError("Invalid Phone number")
            return
          }
          if (stateId == "" || !stateId) {
            toastError("Please Select a state")
            return 0;
          }
          if (cityId == "" || !cityId) {
            toastError("Please Select a City")
            return 0;
          }
          if (areaId == "" || !areaId) {
            toastError("Please Select a Area")
            return 0;
          }
  
          if (pincode == "" || !pincode) {
            toastError("Please Enter pincode")
            return 0;
          }
  
          if (address == "" || !address) {
            toastError("Please Enter  Address")
            return 0;
          }
          let obj = {
            name,
            email,
            phone,
                status,
            stateId,
            cityId,
            areaId,
            address,
            pincode
          }
        console.log(obj, "category obj");
        if (supplierObj?._id) {
            dispatch(SUPPLIERUPDATE(supplierObj._id, obj));
            dispatch(SETSUPPLIEROBJ(null))
        } else {
            dispatch(SUPPLIERADD(obj));
        }
    };

    useEffect(() => {
        if (supplierObj) {
            setName(supplierObj.name ? supplierObj.name : "");
            setEmail(supplierObj.email ? supplierObj.email : "");
            setPhone(supplierObj.phone ? supplierObj.phone : "");
            setStatus(supplierObj.status ? supplierObj.status : "");
            setAddress(supplierObj.address)
            setStateId(supplierObj?.stateId);
            setCityId(supplierObj?.cityId);
            setAreaId(supplierObj?.areaId);
            setPincode(supplierObj?.pincode)
            if (stateArr && stateArr.length > 0) {
                setStateObj(...stateArr.filter(el => el._id == supplierObj?.stateId).map(el => { return { ...el, label: el.name, value: el._id } }))
            }
            setCitiesObj(...citiesArr.filter(el => el._id == supplierObj?.cityId).map(el => { return { ...el, label: el.name, value: el._id } }))
            setAreasObj(...areasArr.filter(el => el._id == supplierObj?.areaId).map(el => { return { ...el, label: el.name, value: el._id } }))
            console.log(supplierObj, "supplierObj")
        }
    }, [supplierObj, stateArr, cityArr, areaArr, citiesArr, areasArr])



    useEffect(() => {


        if (statesArr) {
          setStateArr(statesArr)
        }
        if (citiesArr) {
          setCityArr(citiesArr)
        }
        if (areasArr) {
          setAreaArr(areasArr)
        }
      }, [statesArr, citiesArr, areasArr]);
    
    
    
      useEffect(() => {
        dispatch(STATEGET());
        dispatch(CITYGET());
        dispatch(AREAGET());
      }, [])



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-12 mb-3">
                <label>Phone</label>
                <input
                  className="form-control"
                  type="text"
                  maxLength={10}
                  onChange={(e) => setPhone(e.target.value)} value={phone}
                />
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label>
                  Email Address
                  <span className="red">*</span>
                </label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} name="text" className="form-control" type="email" />
              </div>
                <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      Address Line 2 <span className="red">*</span>
                    </label>
                        <input type="text" className="form-control"  value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                  </div>

                 
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      State <span className="red">*</span>
                    </label>
                    <Select
                      options={stateArr && stateArr.length > 0 && stateArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={stateId} value={stateObj}
                      onChange={(e) => {
                        console.log(e, "asd")
                        setStateId(e.value);
                        setStateObj(e);
                        dispatch(CITYGET(`stateId=${e.value}`));
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      City <span className="red">*</span>
                    </label>
                    <Select
                      options={cityArr && cityArr.length && cityArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={cityId} value={citiesObj}
                      onChange={(e) => {
                        console.log(e, "asd")
                        setCityId(e.value);
                        setCitiesObj(e);
                        dispatch(AREAGET(`cityId=${e.value}`));

                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      Area <span className="red">*</span>
                    </label>
                    <Select
                      options={areaArr && areaArr.length && areaArr.map(el => { return { ...el, value: el._id, label: el.name } })}
                      placeholder="Select from options"
                      defaultInputValue={areaId} value={areasObj}
                      onChange={(e) => {
                        console.log(e, "area")
                        setAreaId(e.value);
                        setAreasObj(e);

                      }}
                    />
                  </div>

                  <div className="col-12 col-md-12">
                    <label className="blue-1 fs-12">
                      Pincode <span className="red">*</span>
                    </label>
                        <input type="text" className="form-control"  value={pincode} onChange={(e)=>{setPincode(e.target.value)}}/>
                  </div>
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddSupplier;
