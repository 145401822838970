import { addSubscription, deleteSubscription, getSubscription, updateSubscription } from "../../../services/Subscription.service";

export const SUBSCRIPTION_ADD = "SUBSCRIPTION_ADD";
export const SUBSCRIPTION_ADD_SUCCESS = "SUBSCRIPTION_ADD_SUCCESS";
export const SUBSCRIPTION_ADD_FAIL = "SUBSCRIPTION_ADD_FAIL";

export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS";
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const GET_ALL_SUBSCRIPTIONS_FAIL = "GET_ALL_SUBSCRIPTIONS_FAIL";

export const UPDATE_SUBSCRIPTION_BY_ID = "UPDATE_SUBSCRIPTION_BY_ID";
export const UPDATE_SUBSCRIPTION_BY_ID_SUCCESS = "UPDATE_SUBSCRIPTION_BY_ID_SUCCESS";
export const UPDATE_SUBSCRIPTION_BY_ID_FAIL = "UPDATE_SUBSCRIPTION_BY_ID_FAIL";

export const SET_SUBSCRIPTION_OBJ = "SET_SUBSCRIPTION_OBJ";
export const SET_SUBSCRIPTION_OBJ_SUCCESS = "SET_SUBSCRIPTION_OBJ_SUCCESS";
export const SET_SUBSCRIPTION_OBJ_FAIL = "SET_SUBSCRIPTION_OBJ_FAIL";

export const GET_SUBSCRIPTION_BY_ID = "GET_SUBSCRIPTION_BY_ID";
export const GET_SUBSCRIPTION_BY_ID_SUCCESS = "GET_SUBSCRIPTION_BY_ID_SUCCESS";
export const GET_SUBSCRIPTION_BY_ID_FAIL = "GET_SUBSCRIPTION_BY_ID_FAIL";

export const DELETE_SUBSCRIPTION_BY_ID = "DELETE_SUBSCRIPTION_BY_ID";
export const DELETE_SUBSCRIPTION_BY_ID_SUCCESS = "DELETE_SUBSCRIPTION_BY_ID_SUCCESS";
export const DELETE_SUBSCRIPTION_BY_ID_FAIL = "DELETE_SUBSCRIPTION_BY_ID_FAIL";

export const SUBSCRIPTIONADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_ADD });
    let { data: response } = await addSubscription(formData);
    if (response) {
      dispatch(SUBSCRIPTIONGET())
      dispatch({
        type: SUBSCRIPTION_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SUBSCRIPTION_ADD_FAIL, payload: err });
  }
};

export const SUBSCRIPTIONGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUBSCRIPTIONS });
    let { data: response } = await getSubscription(formData);
    if (response) {

      dispatch({
        type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SUBSCRIPTION_ADD_FAIL, payload: err });
  }
};

export const SETSUBSCRIPTIONOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SUBSCRIPTION_OBJ });
    if (formData) {
      dispatch({
        type: SET_SUBSCRIPTION_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SUBSCRIPTION_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_SUBSCRIPTION_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const SUBSCRIPTIONUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SUBSCRIPTION_BY_ID });
    let { data: response } = await updateSubscription(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_SUBSCRIPTION_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(SUBSCRIPTIONGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_SUBSCRIPTION_BY_ID_FAIL, payload: err });
  }
};

export const SUBSCRIPTIONDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SUBSCRIPTION_BY_ID });
    let { data: response } = await deleteSubscription(id);
    if (response) {
      console.log(response, "response");
      dispatch(SUBSCRIPTIONGET());
      dispatch({
        type: DELETE_SUBSCRIPTION_BY_ID_SUCCESS,
        payload: response
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_SUBSCRIPTION_BY_ID_FAIL, payload: err });
  }
};