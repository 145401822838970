import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { getOrderById, updateOrderById, updateStatusOrderById } from "../../../services/order.service";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";

function SaleDetail(props) {
  // =========================================================================================

  const [searchParams, setSearchParams] = useSearchParams();
  const [trackingId, setTrackingId] = useState("");
  const [orderObj, setOrderObj] = useState({});
  const [selecetedOrderStatus, setSelecetedOrderStatus] = useState({});
  const getOrder = async () => {
    try {
      const { data: res } = await getOrderById(searchParams.get("orderId"));
      if (res) {
        console.log(res.data);
        setOrderObj(res.data);
        setTrackingId(res.data.trackingId);
        setSelecetedOrderStatus({ label: res.data.orderStatus, value: res.data.orderStatus });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },

    {
      name: "Qty",
      cell: (row) => <p className="fs-14">{row.quantity}</p>,
    },
    {
      name: "Price",
      selector: (row) => `₹ ${row?.price}`,
    },

    {
      name: "Total",
      selector: (row) => parseInt(row?.quantity)*parseInt(row?.price),
    },
  ];

  const handlePrint = async () => {
    // try {
    //   const { data: res } = await getInvoiceById(orderObj?._id);
    //   if (res) {
    //     window.open(`${url}/${res.data}`);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOrderUpdate = async () => {
    try {
      let updateObj = {
        trackingId,
        status: selecetedOrderStatus?.label,
      };
      const { data: res } = await updateOrderById(orderObj?._id, updateObj);
      if (res) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const order_comfirm = [
    { value: generalModelStatuses.ORDERED, label: generalModelStatuses.ORDERED },
    { value: generalModelStatuses.PROCESSED, label: generalModelStatuses.PROCESSED },
    { value: generalModelStatuses.DISPATCHED, label: generalModelStatuses.DISPATCHED },
    { value: generalModelStatuses.DELIVERED, label: generalModelStatuses.DELIVERED },
    { value: generalModelStatuses.CANCELLED, label: generalModelStatuses.CANCELLED },
  ];

  //   =========================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="col-12 col-md-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="blue-1 m-0">Order Details</h5>
              <CustomButton ClickEvent={() => handlePrint()} path={`/Order/Sale-Detail?orderId=${orderObj?._id}`} isLink downloadAble btnName="PRINT" noIcon noIconMargin />
            </div>
          </div>
          <div className="col-12 col-md-12">
          <DashboardBox>
            <div className="row">
       
            <div className="col-md-4">
                    <label>Tracking Id </label>
                    <input type="text" value={trackingId} onChange={(e) => setTrackingId(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-md-4">
                    <label>Order Status </label>
                    <Select options={order_comfirm} onChange={(e) => setSelecetedOrderStatus(e)} value={selecetedOrderStatus} />
                  </div>

                  <div className="col-md-4 text-center mt-2">
                    <CustomButton isBtn btntype="button" ClickEvent={() => handleOrderUpdate()} iconName="fa-solid fa-check" btnName="Save" />
                  </div>
             
            </div>
            </DashboardBox>
          </div>
          <hr/>
          <div className="row">
            <DashboardBox className="col-12 col-md-12 row  m-0">
              {/* <div className="col-12 col-md-6"> */}
              {/* <DashboardBox className="blue-1"> */}
              {/* <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Billing Info</h6>
                    <ul className="blue-1 fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        Devesh
                      </li>
                      <li>
                        <span className="fw-600">
                          Email<span>:</span>
                        </span>
                        devesh.batra@ebslon.com
                      </li>
                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        9999063652
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        506-507, GD ITL, A-09, Netaji Subhash place, Pitampura
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        New Delhi
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        Delhi
                      </li>
                      <li>
                        <span className="fw-600">
                          Country<span>:</span>
                        </span>
                        New Delhi
                      </li>
                      <li>
                        <span className="fw-600">
                          Postcode<span>:</span>
                        </span>
                        110034
                      </li>
                    </ul>
                  </div> */}
              {/* </DashboardBox> */}
              {/* </div> */}
              {/* <div className="col-12 col-md-6">
                <DashboardBox>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize ">Shipped by</h6>
                    <p>
                      Aahilya Creations<br/>

                      C125, Sector 1, Rohini,<br/>
                      New Delhi, Pin- 110085 <br/>
                    </p>
                    {/* <ul className="blue-1 fs-14">
                      <li>
                        <span className="fw-600">
                          Name<span>:</span>
                        </span>
                        {orderObj?.addressObj?.name}
                      </li>
                    
                      <li>
                        <span className="fw-600">
                          Phone<span>:</span>
                        </span>
                        {orderObj?.addressObj?.phone}
                      </li>
                      <li>
                        <span className="fw-600">
                          Address<span>:</span>
                        </span>
                        {orderObj?.addressObj?.address}
                      </li>
                      <li>
                        <span className="fw-600">
                          City<span>:</span>
                        </span>
                        {orderObj?.addressObj?.city}
                      </li>
                      <li>
                        <span className="fw-600">
                          State<span>:</span>
                        </span>
                        {orderObj?.addressObj?.state}
                      </li>
                      <li>
                        <span className="fw-600">
                          Country<span>:</span>
                        </span>
                        {orderObj?.addressObj?.country}
                      </li>
                      <li>
                        <span className="fw-600">
                          Postcode<span>:</span>
                        </span>
                        {orderObj?.addressObj?.pincode}
                      </li>
                    </ul> 
                  </div>
                </DashboardBox>
              </div> */}
              {/* <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Aahilya Creations</h6>
                    <ul className=" fs-14">
                      <li>
                        <span className="fw-600">
                        Order Id<span>:</span>
                        </span>
                        {orderObj?._id}
                      </li>
                      <li>
                        <span className="fw-600">
                        Payment Method<span>:</span>
                        </span>
                        {orderObj?.paymentMethod ??'Razorpay'}
                      </li>
                      <li>
                        <span className="fw-600">
                        Date Added:<span>:</span>
                        </span>
                          {new Date(orderObj?.createdAt).toDateString()}
                      </li>
                      
                    </ul>
                  </div>
                </DashboardBox>
              </div> */}
              <hr/>
              <div className="col-12 col-md-6">
                <DashboardBox>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize ">Shipped to</h6>
                   <ul className="blue-1 fs-14">
                      <li>
                    
                        {orderObj?.userAddress?.name} , {orderObj?.userAddress?.phone}
                      </li>
                    
                      <li>
                      
                       
                      </li>
                      <li>
                      
                        {orderObj?.userAddress?.addressName}
                      </li>
                      <li>
                  
                        {orderObj?.userAddress?.city},{orderObj?.userAddress?.state}
                      </li>
                      <li>
                       
               
                      </li>
                      <li>
                     
                        {orderObj?.userAddress?.country}
                      </li>
                      <li>
                     
                        {orderObj?.userAddress?.pincode}
                      </li>
                    </ul> 
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-md-6">
                <DashboardBox className="blue-1">
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3"> 	Billing Details</h6>
                    <ul className="blue-1 fs-14">
                      <li>
                    
                        {orderObj?.userAddress?.addressLine1} , {orderObj?.userAddress?.phone}
                      </li>
                    
                      <li>
                      
                       
                      </li>
                      <li>
                      
                        {orderObj?.userAddress?.addressName}
                      </li>
                      <li>
                  
                        {orderObj?.userAddress?.city},{orderObj?.userAddress?.state}
                      </li>
                      <li>
                       
               
                      </li>
                      <li>
                     
                        {orderObj?.userAddress?.country}
                      </li>
                      <li>
                     
                        {orderObj?.userAddress?.pincode}
                      </li>
                    </ul> 
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12">
                <DashboardBox className="blue-1">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h5 className="blue-1 mb-3">Package: {orderObj?._id}</h5>
                      <CustomButton redBtn btnName={`${orderObj.status}`} />
                    </div>
                    <h6 className="border py-2 m-0 px-4 text-white bg-black rounded-2 fs-14">Shipping Method : NA</h6>
                  </div>
                  <DashboardTable className="my-4">
                    <DataTable columns={sale_columns} data={orderObj?.items} pagination />
                  </DashboardTable>
                  <div className="customer-profile">
                    <h6 className="blue-1 text-capitalize mb-3">Order Info</h6>
                    <ul className="blue-1 fs-14">
                      <li>
                        <span className="fw-600">
                          Is Paid<span>:</span>
                        </span>
                        No
                      </li>
                      <li>
                        <span className="fw-600">
                          Subtotal<span>:</span>
                        </span>
                        ₹ {orderObj?.totalPrice}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          Discount<span>:</span>
                        </span>
                        - ₹ 0.00
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Shipping Charge<span>:</span>
                        </span>
                        ₹ {orderObj?.shippingCharges ? orderObj?.shippingCharges :0}
                      </li>
                      {/* <li>
                        <span className="fw-600">
                          TAX/GST<span>:</span>
                        </span>
                        ₹ {orderObj?.items?.reduce((acc, el) => acc + el.cgst + el.sgst, 0)}
                      </li> */}
                      <li>
                        <span className="fw-600">
                          Grand Total<span>:</span>
                        </span>
                        ₹ {orderObj?.totalPrice}
                      </li>
                    </ul>
                  </div>
                </DashboardBox>
              </div>
            </DashboardBox>
            {/* <div className="col-12 col-md-4">
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label>Tracking Id </label>
                    <input type="text" value={trackingId} onChange={(e) => setTrackingId(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Order Status </label>
                    <Select options={order_comfirm} onChange={(e) => setSelecetedOrderStatus(e)} value={selecetedOrderStatus} />
                  </div>

                  <div className="col-12 text-center mt-2">
                    <CustomButton isBtn btntype="button" ClickEvent={() => handleOrderUpdate()} iconName="fa-solid fa-check" btnName="Save" />
                  </div>
                </form>
              </DashboardBox>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default SaleDetail;
