import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { BrandReducer } from "./Brand/Brand.reducer";
import { TaxReducer } from "./Tax/Tax.reducer";
import { BannerReducer } from "./Banner/Branner.reducer";
import { userReducer } from "./Users/users.reducer";
import { AttributeReducer } from "./Attribute/Attribute.reducer";
import { CategoryReducer } from "./Category/Category.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { stateReducer } from "./State/States.reducer";
import { cityReducer } from "./City/City.reducer";
import { areaReducer } from "./Area/Area.reducer";
import { wareHouseReducer } from "./WareHouse/WareHouse.reducer";
import { AdminProductOrderStockReducer } from "./AdminProductOrderStock/AdminProductOrderStock";
import { InternalOrderReducer } from "./InternalOrder/InternalOrder.reducer";
import { subscriptionReducer } from "./Subscription/Subscription.reducer";
import { serviceReducer } from "./Service/Service.reducer";
import { supplierReducer } from "./Supplier/Supplier.reducer"
const RootReducer = combineReducers({
  auth: authReducer,
  brand: BrandReducer,
  taxes: TaxReducer,
  banner: BannerReducer,
  users: userReducer,
  states: stateReducer,
  city: cityReducer,
  area: areaReducer,
  warehouse: wareHouseReducer,
  attribute: AttributeReducer,
  category: CategoryReducer,
  product: ProductReducer,
  AdminProductOrderStock: AdminProductOrderStockReducer,
  internalOrder: InternalOrderReducer,
  subscription: subscriptionReducer,
  service: serviceReducer,
  supplier: supplierReducer,
});

export default RootReducer;
