export const PERMINSSION_CONST_ARR = [
  {
    name: "Location",
    value: "location",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Service",
    value: "service",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Category",
    value: "category",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },

  {
    name: "Brand",
    value: "brand",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Attribute",
    value: "attribute",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Product",
    value: "product",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Coupon",
    value: "coupon",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Home Page",
    value: "homePage",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "User",
    value: "user",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "KYC",
    value: "kyc",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Internal Orders",
    value: "internalOrder",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Delivery",
    value: "delivery",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Order",
    value: "order",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
  {
    name: "Report",
    value: "order",
  permission: {
        READ: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false
      },
  },
];
