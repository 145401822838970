import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/subscription";


export const addSubscription = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getSubscription = (query) => {
    return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteSubscription = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateSubscription = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};