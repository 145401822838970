import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/supplier";

export const addSupplier = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getSupplier = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getSupplier?${query}`);
};

export const deleteSupplier = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateSupplier = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

