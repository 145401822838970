import {
  add,
  get,
  getInternalOrderById,
  internalOrderUpdateStatus,
} from "../../../services/InternalOrder.services";

export const INTERNALORDER_ADD = "INTERNALORDER_ADD";
export const INTERNALORDER_ADD_SUCCESS = "INTERNALORDER_ADD_SUCCESS";
export const INTERNALORDER_ADD_FAIL = "INTERNALORDER_ADD_FAIL";

export const INTERNALORDER_UPDATE = "INTERNALORDER_UPDATE";
export const INTERNALORDER_UPDATE_SUCCESS = "INTERNALORDER_UPDATE_SUCCESS";
export const INTERNALORDER_UPDATE_FAIL = "INTERNALORDER_UPDATE_FAIL";

export const GET_ALL_INTERNALORDERS = "GET_ALL_INTERNALORDERS";
export const GET_ALL_INTERNALORDERS_SUCCESS = "GET_ALL_INTERNALORDERS_SUCCESS";
export const GET_ALL_INTERNALORDERS_FAIL = "GET_ALL_INTERNALORDERS_FAIL";


export const SET_INTERNALORDER_OBJ = "SET_INTERNALORDER_OBJ";
export const SET_INTERNALORDER_OBJ_SUCCESS = "SET_INTERNALORDER_OBJ_SUCCESS";
export const SET_INTERNALORDER_OBJ_FAIL = "SET_INTERNALORDER_OBJ_FAIL";

export const GET_INTERNALORDER_BY_ID = "GET_INTERNALORDER_BY_ID";
export const GET_INTERNALORDER_BY_ID_SUCCESS = "GET_INTERNALORDER_BY_ID_SUCCESS";
export const GET_INTERNALORDER_BY_ID_FAIL = "GET_INTERNALORDER_BY_ID_FAIL";


export const GET_RELATED_INTERNALORDERS = "GET_RELATED_INTERNALORDERS";
export const GET_RELATED_INTERNALORDERS_SUCCESS = "GET_RELATED_INTERNALORDERS_SUCCESS";
export const GET_RELATED_INTERNALORDERS_FAIL = "GET_RELATED_INTERNALORDERS_FAIL";


export const GET_ALL_ROLE_INTERNALORDERS = "GET_ALL_ROLE_INTERNALORDERS";
export const GET_ALL_ROLE_INTERNALORDERS_SUCCESS = "GET_ALL_ROLE_INTERNALORDERS_SUCCESS";
export const GET_ALL_ROLE_INTERNALORDERS_FAIL = "GET_ALL_ROLE_INTERNALORDERS_FAIL";

// export const GET_ALL_NESTED_CATEGORIES = "GET_ALL_NESTED_CATEGORIES";
// export const GET_ALL_NESTED_CATEGORIES_SUCCESS = "GET_ALL_NESTED_CATEGORIES_SUCCESS";
// export const GET_ALL_NESTED_CATEGORIES_FAIL = "GET_ALL_NESTED_CATEGORIES_FAIL";

export const INTERNALORDERAdd = (formData) => async (dispatch) => {
  try {
    // console.log(formData, "formData");
    dispatch({ type: INTERNALORDER_ADD });
    let { data: response } = await add(formData);
    // console.log(response, "response in action");
    if (response) {
      console.log(response, "response in action");
      dispatch({
        type: INTERNALORDER_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: INTERNALORDER_ADD_FAIL, payload: err });
  }
};

export const INTERNALORDERGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_INTERNALORDERS });
    let { data: response } = await get(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_INTERNALORDERS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: INTERNALORDER_ADD_FAIL, payload: err });
  }
};



export const INTERNALORDER_ROLE_Get = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ROLE_INTERNALORDERS });
    let { data: response } = await get(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_ROLE_INTERNALORDERS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_ROLE_INTERNALORDERS_FAIL, payload: err });
  }
};

export const INTERNALORDERGETBYID = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: GET_INTERNALORDER_BY_ID });
    let { data: response } = await getInternalOrderById(id,query);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_INTERNALORDER_BY_ID_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_INTERNALORDER_BY_ID_FAIL, payload: err });
  }
};

export const SetINTERNALORDERObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_INTERNALORDER_OBJ });
    if (formData) {
      dispatch({
        type: SET_INTERNALORDER_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_INTERNALORDER_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_INTERNALORDER_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};


export const INTERNALUPDATE_STATUS = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: INTERNALORDER_UPDATE });
    let { data: response } = await internalOrderUpdateStatus(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: INTERNALORDER_UPDATE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: INTERNALORDER_UPDATE_FAIL, payload: err });
  }
};

