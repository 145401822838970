import React, { useState, useEffect } from "react";
import Select from "react-select";

import CustomButton from "../Utility/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsOnlyNameAndId } from "../../services/product.service";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import {
  ConstructionOutlined,
  ContentPasteSearchOutlined,
} from "@mui/icons-material";

import {
  ADMINPRODUCTORDERSTOCKAdd,
  ADMINPRODUCTORDERSTOCKGet,
  SetADMINPRODUCTORDERSTOCKObj,
  ADMINPRODUCTORDERSTOCKUpdate,
  ADMINPRODUCTORDERSTOCKDelete,
} from "../../redux/actions/AdminProductOrderStocks/AdminProductOrderStocks.actions";
function AddStocksProduct() {
  const dispatch = useDispatch();

  const [attributeArr, setAttributeArr] = useState([]);
  const [stock, setStock] = useState("");
  const [dealProductArr, setDealProductArr] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [productArray2, setProductArray2] = useState([]);
  const [attributeName, setAttributeName] = useState([]);
  const [productArray3, setProductArray3] = useState([]);
  //=======================================
  useEffect(() => {
    let temp = dealProductArr; //
    // console.log(dealProductArr, "dealProductArr");

    // console.log(productArray2, "productArray232");
    // let length = -1;
    // let tempLength = temp.length;
    // // length = length + 1;
    // // if (length == tempLength) {
    // console.log(temp.length, "length42");
    // if (tempLength >= length) {
    //   while (tempLength > length) {
    //     length++;
    //   }
    // }
    // while (tempLength >= length) {
    //   length++;
    // }
    // console.log(length, "length1242");
    // =================
    // console.log(productArray2.length, " productArray2.length > 0");
    // if (tempLength <= length) {
    // if (productArray2 && productArray2.length > 0) {
    //   // setProductArray2([...temp]);
    //   let test = productArray2.map((ele) => {
    //     console.log(ele, "test34");
    //     // let findIndex = dealProductArr.find((el) => el.name == ele.name);
    //     let findIndex = temp.find((el) => el.name == ele.name);
    //     console.log(findIndex, "findIndex21");
    //     if (findIndex) {
    //       // setProductArray3([...el]);
    //       return ele;
    //     }
    //   });
    //   if(test){

    //   }else{

    //   }

    //   // .filter((el) => el);
    //   // console.log(test, "tes12t34");
    //   // setProductArray2([...test]);
    //   setProductArray2([...test]);
    //   // } else {
    //   setProductArray2([...temp]);
    //   console.log(productArray2, "productArray232");
    //   // }
    // } else {
    //   setProductArray2([...temp]);
    //   // setProductArray3([...temp ])
    // }
    ///====================================
    if (productArray2 && productArray2.length > 0) {
      // setProductArray2([...temp]);
      let test = temp.map((ele) => {
        // console.log(ele, "test34");
        // let findIndex = dealProductArr.find((el) => el.name == ele.name);
        let findIndex = productArray2.find((el) => el.name == ele.name);
        // console.log(findIndex, "findproductArray2");
        if (!findIndex) {
          // console.log(" findproductArray2");
          setProductArray2((prev) => [...prev, ele]);
          // return ele;
        }
        // console.log(findIndex, "findIndex2");

        // }
      });

      let test2 = temp.map((ele) => {
        let findInArrayItself = productArray2.findIndex(
          (elem) => elem.name == ele.name
        );
        console.log(findInArrayItself, "findInArrayIts2");
        // if (findInArrayItself) {
        //   setProductArray3((prev) => [ele]);
        //   // setProductArray2((prev) => [...prev, ele]);
        // }
        if (findInArrayItself != -1) {
          return ele;
        }
      });
      if (test2) {
        console.log(test2, "test23");
        setProductArray3([...test2]);
      }
    } else {
      setProductArray2([...temp]);
      setProductArray3([...temp]);
    }
    // let test2 = temp.map((ele) => {
    //   let findInArrayItself = productArray2.find(
    //     (elem) => elem.name == ele.name
    //   );
    //   // console.log(findInArrayItself, "findInArrayIts2");
    //   // if (findInArrayItself) {
    //   //   setProductArray3((prev) => [ele]);
    //   //   // setProductArray2((prev) => [...prev, ele]);
    //   // }
    //   return findInArrayItself;
    // });
    // if (test2) {
    //   setProductArray3([...test2]);
    // }
    // }
  }, [dealProductArr]);

  // useEffect(() => {
  //   console.log(productArray2, "productArray22222");
  //   let temp = dealProductArr; //
  // let test = temp.map((ele) => {
  //   let findInArrayItself = productArray2.find(
  //     (elem) => elem.name == ele.name
  //   );
  //   // console.log(findInArrayItself, "findInArrayIts2");
  //   // if (findInArrayItself) {
  //   //   setProductArray3((prev) => [ele]);
  //   //   // setProductArray2((prev) => [...prev, ele]);
  //   // }
  //   return findInArrayItself;
  // });
  // if (test) {
  //   setProductArray3([...test]);
  // }
  // }, [productArray2]);
  useEffect(() => {
    console.log(productArray3, "productArray3333");
  }, [productArray3]);
  //=================================
  const getPRoduct = async () => {
    let arr = await getAllProductsOnlyNameAndId();
    setProductArray(arr.data.data);
    // let arr2 = arr.data.data;
  };
  // console.log(attributeArr, "2323';");
  useEffect(() => {
    getPRoduct();
  }, []);
  // console.log(productArray, " productArray3");

  // useEffect(() => {}, [dealProductArr]);

  const handleAdd = (e) => {
    e.preventDefault();
    // if (title == "") {
    //   toastError("Title is mandatory");
    //   return;
    // }

    // console.log(dealProductArr, "dealProductArr3");
    let obj = {
      // title,
      productList: productArray2,
    };
    // console.log(obj, "obj2");
    dispatch(ADMINPRODUCTORDERSTOCKAdd(obj));
  };
  // console.log(dealProductArr, "DealProductArr23");
  const handleRemove = () => {
    let tempArr = dealProductArr.filter(
      (el, index) => index != dealProductArr.length - 1
    );
    setDealProductArr([...tempArr]);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12  col-md-8">
              <h5 className="blue-1 mb-4">Products Stocks Order </h5>
              <DashboardBox>
                <form action="#" className="form">
                  <div className="col-12 col-md-12">
                    {/* {console.log(productArray, "1234")} */}
                    <label>
                      Products <span className="red">*</span>
                    </label>
                    <Select
                      isMulti
                      options={productArray.map((el) => {
                        return {
                          ...el,
                          value: el._id,
                          label: el.name,
                          productId: el._id,
                        };
                      })}
                      placeholder="Select from options"
                      onChange={(e) => {
                        // console.log(e, "value");
                        setDealProductArr(e);
                      }}
                    />
                    {/* {console.log(dealProductArr, "dealProductArr3")} */}
                    {productArray3 &&
                      productArray3.length > 0 &&
                      productArray3.map((el) => {
                        // console.log(el, " epol");
                        return (
                          <div>
                            <div className="col-12 ">
                              <div className="col-12 mb-3">
                                <label>
                                  Product Name<span className="red">*</span>
                                </label>
                                {/* {el.name} */}
                                <input
                                  className="form-control"
                                  readOnly
                                  defaultValue={el.name}
                                />
                              </div>
                              {el.attributesArr.length > 0 &&
                                el.attributesArr.map((elee) => {
                                  return (
                                    <div className="border-bottom pb-3 mb-4 row">
                                      <div className="col-12 col-md-5 mb-3">
                                        <label>
                                          variant name
                                          <span className="red">*</span>
                                        </label>
                                        <input
                                          readOnly
                                          className="form-control"
                                          defaultValue={elee.name}
                                        />
                                      </div>
                                      <div className="col-12 col-md-5  mb-3">
                                        <label>
                                          -variant Stocks-
                                          <span className="red">*</span>
                                        </label>
                                        {elee.currentStock}
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            elee.currentStock
                                              ? elee.currentStock
                                              : 0
                                          }
                                          onChange={(e) => {
                                            setStock(e.target.value);
                                            productArray3.map((ele) => {
                                              // console.log(ele, "el1o3332");
                                              ele.attributeArr.map((el) => {
                                                if (el.name == elee.name) {
                                                  // console.log("einside");
                                                  el.currentStock =
                                                    e.target.value;
                                                }
                                              });
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* {stock} */}
                              {el.attributesArr &&
                                el.attributesArr.length == 0 && (
                                  <div className="col-12 col-md-7  mb-3">
                                    <label>
                                      -Stocks- <span className="red">*</span>
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={el.stock ? el.stock : 0}
                                      onChange={(e) => {
                                        // setStock(e.target.value);
                                        productArray3.map((ele) => {
                                          // console.log(ele, "ele9 inside");
                                          if (ele.name == el.name) {
                                            // console.log(ele.name, "elemo9");
                                            el.stock = e.target.value;
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              {/* <CustomButton
                              btntype="button"
                              ClickEvent={handleRemove}
                              isBtn
                              noIcon
                              btnName="remove"
                            /> */}
                            </div>
                          </div>
                        );
                      })}
                    <div>
                      {/* {console.log(productArray3, "productArray3-final")} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      ClickEvent={handleAdd}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AddStocksProduct;
