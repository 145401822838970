import { Route, Routes, useNavigate } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import { useSelector } from "react-redux";
import Profile from "../components/Admin/Profile";
import AddBlog from "../components/Blog/AddBlog";
import Blog from "../components/Blog/Blog";
import BlogCategory from "../components/Blog/BlogCategory";
import ShowBlog from "../components/Blog/ShowBlog";
import CompanyInfo from "../components/CompanyInfo/CompanyInfo";
import ContactMail from "../components/ContactMail/ContactMail";
import ShowContact from "../components/ContactMail/ShowContact";
import AddCustomer from "../components/Customer/AddCustomer";
import Customer from "../components/Customer/Customer";
import ProductDetail from "../components/Customer/CustomerDetail";
import Dashboard from "../components/dashboard/Dashboard";
import AddEmailTemplate from "../components/EmailTemplate/AddEmailTemplate";
import EmailTemplate from "../components/EmailTemplate/EmailTemplate";
import AddBanner from "../components/Frontend-CMS/Banners/AddBanner";
import Banners from "../components/Frontend-CMS/Banners/Banners";
import ViewSubscription from "../components/Frontend-CMS/Subscription/ViewSubscription";
import AddSubscription from "../components/Frontend-CMS/Subscription/AddSubscription";
import AddContactInfo from "../components/Frontend-CMS/ContactInfo/AddContactInfo";
import ContactInfo from "../components/Frontend-CMS/ContactInfo/ContactInfo";
import AddFaq from "../components/Frontend-CMS/FAQ/AddFaq";
import Faq from "../components/Frontend-CMS/FAQ/Faq";
import AddLogo from "../components/Frontend-CMS/Logo/AddLogo";
import Logo from "../components/Frontend-CMS/Logo/Logo";
import AddPrivacy from "../components/Frontend-CMS/PrivacyPolicy/AddPrivacy";
import Privacy from "../components/Frontend-CMS/PrivacyPolicy/Privacy";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import AddMenu from "../components/Menus/AddMenu";
import EditMenu from "../components/Menus/EditMenu";
import MenuItem from "../components/Menus/MenuItem";
import Menus from "../components/Menus/Menus";
import CancleReason from "../components/Order/CancleReason/CancleReason";
import DeliveryProcess from "../components/Order/DeliveryProcess/DeliveryProcess";
import SaleDetail from "../components/Order/Detail/SaleDetail";
import InHouse from "../components/Order/InhouseOrder/Inhouse";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";
import TrackOrder from "../components/Order/TrackOrder/TrackOrder";
import PaymentGateway from "../components/PaymentGateway/PaymentGateway";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";
import AddBrand from "../components/Products/Brand/AddBrand";
import Brand from "../components/Products/Brand/Brand";
import BulkBrandUpload from "../components/Products/Brand/BulkBrandUpload";
import BulkProductUpload from "../components/Products/BulkProductUpload";
import BulkCategoryUpload from "../components/Products/Category/BulkCategoryUpload";
import Category from "../components/Products/Category/Category";
import Config from "../components/Products/Config";
import ProductList from "../components/Products/ProductList";
import ProductReview from "../components/Review/ProductReview";
import ReviewConfig from "../components/Review/ReviewConfig";
import SellerReview from "../components/Review/SellerReview";
import AddCurrency from "../components/SetUp/CurrencyList/AddCurrency";
import CurrencyList from "../components/SetUp/CurrencyList/CurrencyList";
import Location from "../components/SetUp/Location/Location";
import Tags from "../components/SetUp/Tags/Tags";
import SideBar from "../components/Sidebar/SideBar";
import GstConfig from "../components/Tax/GstConfig";
import GstSetup from "../components/Tax/GstSetup";
import AddUser from "../components/Users/AddUser";
import EditUser from "../components/Users/EditUser";
import UserDetail from "../components/Users/UserDetail";
import User from "../components/Users/Users";
import DeliveryUser from "../components/Delivery/Users";
import AddDeliveryUser from "../components/Delivery/AddUser";
import EditDelivery from "../components/Delivery/EditUser";
import UserDetailDelivery from "../components/Delivery/UserDetail";
import AccountsLedger from "../components/AccountsLedger/AccountsLedger";
import SettlementDetails from "../components/dashboard/SettlementDetails";
import NotificationsAdd from "../components/EmailTemplate/AddNotifications";
import NotificationsView from "../components/EmailTemplate/Notifications";
import CloneGeneralProduct from "../components/Products/AddProduct/CloneProduct";
import UpdateProductListPage from "../components/Products/AddProduct/UpdateProductListPage";
import GeneralSettings from "../components/SelllerSettings/GeneralSettings";
import ViewStates from "../components/Location/States/ViewStates";
import AddStates from "../components/Location/States/AddStates";
import AddArea from "../components/Location/Area/AddArea";
import ViewArea from "../components/Location/Area/ViewArea";
import ViewWareHouse from "../components/WareHouse/ViewWareHouse";
import AddWareHouse from "../components/WareHouse/AddWareHouse";
import AddCity from "../components/Location/City/AddCity";
import ViewCity from "../components/Location/City/ViewCity";
import StocksProduct from "../components/AdminProductOrderStocks/StockManagement";
import AddStocksProduct from "../components/AdminProductOrderStocks/stockadd";

import { useEffect } from "react";
import InternalOrders from "../components/InternalOrders/InternalOrders";
import AllInternalOrders from "../components/InternalOrders/AllInternalOrders";
import ViewInternalOrders from "../components/InternalOrders/ViewInternalOrder";
import AllUserInternalOrders from "../components/InternalOrders/AllUserInternalOrders";
import ViewInternalUserOrder from "../components/InternalOrders/ViewInternalUserOrder";
import ViewStore from "../components/Store/ViewStore";
import ProductStock from "../components/Products/ProductStock";
import SingleProductStock from "../components/Products/SingleProductStock";
import PendingOrder from "../components/Order/TotalOrder/PendingOrder";
import Offer from "../components/Frontend-CMS/Offer/Offer";
import AddOffer from "../components/Frontend-CMS/Offer/AddOffer";
import AddSale from "../components/Frontend-CMS/Sale/AddSale";
import Sale from "../components/Frontend-CMS/Sale/Sale";
import BuyInternalOrders from "../components/InternalOrders/BuyInternalOrders";
import SellInternalOrders from "../components/InternalOrders/SellInternalOrder";
import CreateBuyInternalOrder from "../components/InternalOrders/CreateBuyInternalOrder";
import AllStoreInternalOrders from "../components/InternalOrders/AllStoreInternalOrders";
import AddCoupon from "../components/Coupon/AddCoupon";
import ViewCoupon from "../components/Coupon/ViewCoupon";
import Kyc from "../components/KYC/Kyc";
import { rolesObj } from "../utils/roles";
import { generalModelStatuses } from "../components/Utility/constants";
import ViewOfflineStore from "../components/Store/ViewOfflineStore ";
import ViewServices from "../components/Service/ViewService";
import ViewSupplier from "../components/Location/Supplier/ViewSupplier";
import HomePage from "../components/Frontend-CMS/HomePage/HomePage";
import Orders from "../components/Delivery/Orders";
import Payment from "../components/Delivery/Payment";
import Settlement from "../components/Delivery/Settlement";
import StockReport from "../components/Reports/StockReport";
import SaleReport from "../components/Reports/SaleReport";
import ProductSoldAmount from "../components/Reports/ProductSoldAmount";


export default function AuthorizedRoutes() {
  let token = useSelector((state) => state.auth.token);
  let role = useSelector((state) => state.auth.role);
  let user = useSelector((state) => state.auth.user);
  let navigate = useNavigate()

  useEffect(() => {
    console.log(role, "role",[rolesObj.ADMIN,rolesObj.SUBADMIN].includes(role))
    if (![rolesObj.ADMIN,rolesObj.SUBADMIN].includes(role)) {

      console.log(user, "useruseruseruseruser")
      if (user && user.isKyc != generalModelStatuses.APPROVED)
        navigate('/kyc')
    }
  }, [token]);

  console.log(token, "token");
  return (
    <section className="body_bg">
      <div className="row g-0">
        <div className="col-12 col-md-2" style={{ contain: "content" }}>
          <SideBar />
        </div>
        <div
          className="col-12 col-md-10"
          style={{ height: "100vh", overflow: "hidden scroll" }}
        >
          <Header />
          <Routes>
            <Route exact path="/" element={<Dashboard />}></Route>

            <Route exact path="/internal-order/CreateInternalOrder" element={<InternalOrders />}></Route>
            <Route exact path="/internal-order/CreateBuyInternalOrder" element={<CreateBuyInternalOrder />}></Route>
            <Route exact path="/internal-order/BuyInternalOrders" element={<BuyInternalOrders />}></Route>
            <Route exact path="/internal-order/SellInternalOrder" element={<SellInternalOrders />}></Route>.
            <Route exact path="/internal-order/AllInternalOrder" element={<AllInternalOrders />}></Route>
            <Route exact path="/internal-order/AllWareHouseOrder" element={<AllUserInternalOrders />}></Route>
            <Route exact path="/internal-order/AllStoreOrder" element={<AllStoreInternalOrders />}></Route>
            <Route exact path="/internal-order/View-internal-order/:id" element={<ViewInternalOrders />}></Route>
            <Route exact path="/internal-order-WareHouse/View-internal-order/:id" element={<ViewInternalUserOrder />}></Route>
            <Route exact path="/internal-order-Store/View-internal-order/:id" element={<ViewInternalUserOrder />}></Route>

            <Route exact path="/Accounts-and-Ledger" element={<AccountsLedger />}></Route>
            <Route exact path="/Product/Category" element={<Category />}></Route>
            <Route
              exact
              path="/Settlement-Details"
              element={<SettlementDetails />}
            ></Route>
            <Route
              exact
              path="/Location/View-States"
              element={<ViewStates />}
            ></Route>
            <Route
              exact
              path="/Location/Add-States"
              element={<AddStates />}
            ></Route>
            <Route
              exact
              path="/Location/View-City"
              element={<ViewCity />}
            ></Route>
            <Route
              exact
              path="/Location/Add-City"
              element={<AddCity />}
            ></Route>
            <Route
              exact
              path="/Location/View-Area"
              element={<ViewArea />}
            ></Route>
            <Route
              exact
              path="/Location/Add-Area"
              element={<AddArea />}
            ></Route>
            <Route
              exact
              path="/Warehouse/View-Warehouse"
              element={<ViewWareHouse />}
            ></Route>
            <Route
              exact
              path="/Warehouse/View-Store"
              element={<ViewStore />}
            ></Route>
            <Route
              exact
              path="/Warehouse/View-Vendor"
              element={<ViewOfflineStore />}
            ></Route>
            <Route
              exact
              path="/Warehouse/Add-Warehouse"
              element={<AddWareHouse />}
            ></Route>
            <Route
              exact
              path="/Accounts-and-Ledger"
              element={<AccountsLedger />}
            ></Route>
            <Route
              exact
              path="/Product/Category"
              element={<Category />}
            ></Route>
            <Route
              exact
              path="/Product/Service"
              element={<ViewServices />}
            ></Route>
            <Route
              exact
              path="/Supplier/View-Supplier"
              element={<ViewSupplier />}
            ></Route>
            <Route exact path="/Product/Brand" element={<Brand />}></Route>
            <Route
              exact
              path="/Product/clone-Product"
              element={<CloneGeneralProduct />}
            ></Route>
            <Route
              exact
              path="/Product/Brand-Create"
              element={<AddBrand />}
            ></Route>
            <Route
              exact
              path="/Product/Update-Product"
              element={<UpdateProductListPage />}
            ></Route>
            <Route
              exact
              path="/Product/Attribute"
              element={<Attribute />}
            ></Route>
            <Route
              exact
              path="/Product/Attribute-Value"
              element={<AttributeValue />}
            ></Route>
            <Route
              exact
              path="/Product/AddProduct"
              element={<AddProduct />}
            ></Route>
            <Route
              exact
              path="/Product/Bulk-Product-Upload"
              element={<BulkProductUpload />}
            ></Route>
            <Route
              exact
              path="/Product/Bulk-Category-Upload"
              element={<BulkCategoryUpload />}
            ></Route>
            <Route
              exact
              path="/Product/Bulk-Brand-Upload"
              element={<BulkBrandUpload />}
            ></Route>
            <Route
              exact
              path="/Product/Product-List"
              element={<ProductList />}
            ></Route>
            <Route
              exact
              path="/Product/Product-Stock"
              element={<ProductStock />}
            ></Route>
            <Route
              exact
              path="/Product/Single-Product-Stock/:id/:variantId"
              element={<SingleProductStock />}
            ></Route>
            <Route exact path="/Coupon/View-Coupon" element={<ViewCoupon />}></Route>
            <Route exact path="/Coupon/Add-Coupon" element={<AddCoupon />}></Route>

            <Route exact path="/Delivery/User" element={<DeliveryUser />}></Route>
            <Route exact path="/Delivery/Payment/:id" element={<Payment />}></Route>
            <Route exact path="/Delivery/Settlement/:id" element={<Settlement />}></Route>
            <Route exact path="/Delivery/Orders/:id" element={<Orders />}></Route>
            <Route exact path="/Delivery/AddDeliveryUser" element={<AddDeliveryUser />}></Route>
            <Route exact path="/Delivery/EditDelivery" element={<EditDelivery />}></Route>
            <Route exact path="/Delivery/UserDetailDelivery" element={<UserDetailDelivery />}></Route>
            <Route exact path="/Subscription/View-Subscription" element={<ViewSubscription />}></Route>
            <Route exact path="/Subscription/Add-Subscription" element={<AddSubscription />}></Route>

            <Route exact path="/Product/Config" element={<Config />}></Route>
            <Route exact path="/Admin/Profile" element={<Profile />}></Route>
            <Route exact path="/Blog/post" element={<Blog />}></Route>
            <Route exact path="/Blog/post/create" element={<AddBlog />}></Route>
            <Route exact path="/Email/post" element={<EmailTemplate />}></Route>
            <Route
              exact
              path="/Email/post/create"
              element={<AddEmailTemplate />}
            ></Route>
            <Route
              exact
              path="/kyc"
              element={<Kyc />}
            ></Route>
            <Route
              exact
              path="/Notification/post"
              element={<NotificationsView />}
            ></Route>
            <Route
              exact
              path="/Notification/post/create"
              element={<NotificationsAdd />}
            ></Route>
            <Route
              exact
              path="/Blog/Category"
              element={<BlogCategory />}
            ></Route>
            <Route exact path="/Blog/View-Post" element={<ShowBlog />}></Route>
            <Route
              exact
              path="/Order/Total-Order"
              element={<PendingOrder />}
            ></Route>
            <Route
              exact
              path="/Order/Inhouse-Order"
              element={<InHouse />}
            ></Route>
            <Route
              exact
              path="/Order/Delivery-Process"
              element={<DeliveryProcess />}
            ></Route>
            <Route
              exact
              path="/Order/Cancle-Reason"
              element={<CancleReason />}
            ></Route>
            <Route
              exact
              path="/Order/Track-Order"
              element={<TrackOrder />}
            ></Route>
            <Route
              exact
              path="/Order/Sale-Detail"
              element={<SaleDetail />}
            ></Route>
            <Route exact path="/Contact-Mail" element={<ContactMail />}></Route>
            <Route exact path="/Contact-Info" element={<ShowContact />}></Route>
            <Route
              exact
              path="/Review/Product-Review"
              element={<ProductReview />}
            ></Route>
            <Route
              exact
              path="/Review/Seller-Review"
              element={<SellerReview />}
            ></Route>
            <Route
              exact
              path="/Review/Review-Configuration"
              element={<ReviewConfig />}
            ></Route>
            <Route
              exact
              path="/settings/general"
              element={<GeneralSettings />}
            ></Route>
            <Route
              exact
              path="/Company-Information"
              element={<CompanyInfo />}
            ></Route>
            <Route exact path="/Menus" element={<Menus />}></Route>
            <Route
              exact
              path="/Menus/Menus-Create"
              element={<AddMenu />}
            ></Route>
            <Route
              exact
              path="/Menus/Menus-Edit"
              element={<EditMenu />}
            ></Route>
            <Route
              exact
              path="/Menus/Menus-Item"
              element={<MenuItem />}
            ></Route>
            <Route
              exact
              path="/Payment-Gateway"
              element={<PaymentGateway />}
            ></Route>
            <Route exact path="/GST-SETUP" element={<GstSetup />}></Route>
            <Route
              exact
              path="/GST-Configuation"
              element={<GstConfig />}
            ></Route>
            <Route exact path="/Customer-list" element={<Customer />}></Route>
            <Route
              exact
              path="/Customer-Create"
              element={<AddCustomer />}
            ></Route>
            <Route
              exact
              path="/Customer/KYC"
              element={<Customer />}
            ></Route>
            <Route
              exact
              path="/Customer-Detail"
              element={<ProductDetail />}
            ></Route>
            <Route exact path="/User-list" element={<User />}></Route>
            <Route
              exact
              path="/User/User-edit/:id"
              element={<EditUser />}
            ></Route>
            <Route exact path="/User-Create" element={<AddUser />}></Route>
            <Route exact path="/User-Detail" element={<UserDetail />}></Route>
            <Route exact path="/Banners" element={<Banners />}></Route>
            <Route
              exact
              path="/Banners/Banner-Create"
              element={<AddBanner />}
            ></Route>
            <Route exact path="/Offer" element={<Offer />}></Route>
            <Route
              exact
              path="/Offer/Offer-Create"
              element={<AddOffer />}
            ></Route>

            <Route
              exact
              path="/HomePage"
              element={<HomePage />}
            ></Route>
            <Route exact path="/Sales" element={<Sale />}></Route>
            <Route
              exact
              path="/Sales/Sales-Create"
              element={<AddSale />}
            ></Route>
            <Route exact path="/Logo" element={<Logo />}></Route>
            <Route exact path="/Logo/Logo-Create" element={<AddLogo />}></Route>
            <Route exact path="/ContactInfo" element={<ContactInfo />}></Route>
            <Route
              exact
              path="/Contact-Info/Contact-Info-Create"
              element={<AddContactInfo />}
            ></Route>
            <Route exact path="/Faq" element={<Faq />}></Route>
            <Route exact path="/Faq/Faq-Create" element={<AddFaq />}></Route>
            <Route exact path="/PrivacyPolicy" element={<Privacy />}></Route>
            <Route
              exact
              path="/PrivacyPolicy/PrivacyPolicy-Create"
              element={<AddPrivacy />}
            ></Route>
            <Route
              exact
              path="/SetUp/Currency-List"
              element={<CurrencyList />}
            ></Route>
            <Route
              exact
              path="/SetUp/Currency-Create"
              element={<AddCurrency />}
            ></Route>
            <Route exact path="/SetUp/Location" element={<Location />}></Route>
            <Route exact path="/SetUp/Tags" element={<Tags />}></Route>
            {/* AddStocksProduct */}
            <Route
              exact
              path="/ProductStocks-Order"
              element={<StocksProduct />}
            ></Route>
            <Route
              exact
              path="/ProductStocks-Order/Add"
              element={<AddStocksProduct />}
            ></Route>
            <Route
              exact
              path="/Stock-Report"
              element={<StockReport />}
            ></Route>
            <Route
              exact
              path="/Sale-Report"
              element={<SaleReport />}
            ></Route>
            <Route
              exact
              path="/ProductSoldAmount"
              element={<ProductSoldAmount />}
            ></Route>
          </Routes>
          <Footer />
        </div>
      </div>
    </section>
  );
}
