import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as INTERNALORDER from "../../actions/InternalOrder/InternalOrder.actions";

const initialState = {
  internalOrder: null,
  internalRoleOrder: null,
  internalOrderObj: null,
  loading: false,
  error: null,
};

export const InternalOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERNALORDER.INTERNALORDER_ADD:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.INTERNALORDER_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case INTERNALORDER.INTERNALORDER_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case INTERNALORDER.INTERNALORDER_UPDATE:
        return {
          ...state,
          loading: true,
        };
      case INTERNALORDER.INTERNALORDER_UPDATE_SUCCESS:
        toastSuccess(action.payload);
  
        return {
          ...state,
          loading: false,
        };
      case INTERNALORDER.INTERNALORDER_UPDATE_FAIL:
        toastError(action.payload);
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  

    case INTERNALORDER.GET_ALL_INTERNALORDERS:
      return {
        ...state,
        loading: true,
        internalOrder: [],
      };
    case INTERNALORDER.GET_ALL_INTERNALORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        internalOrder: action.payload.data,
      };
    case INTERNALORDER.GET_ALL_INTERNALORDERS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case INTERNALORDER.GET_ALL_ROLE_INTERNALORDERS:
        return {
          ...state,
          loading: true,
        };
      case INTERNALORDER.GET_ALL_ROLE_INTERNALORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          internalRoleOrder: action.payload.data,
        };
      case INTERNALORDER.GET_ALL_ROLE_INTERNALORDERS_FAIL:
        toastError(action.payload);
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    case INTERNALORDER.GET_RELATED_INTERNALORDERS:
      return {
        ...state,
        InternalOrders: [],
        loading: true,
      };
    case INTERNALORDER.GET_RELATED_INTERNALORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        internalOrder: action.payload.data,
      };
    case INTERNALORDER.GET_RELATED_INTERNALORDERS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case INTERNALORDER.SET_INTERNALORDER_OBJ:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.SET_INTERNALORDER_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        internalOrderObj: action.payload.data,
        loading: false,
        error: null,
      };
    case INTERNALORDER.SET_INTERNALORDER_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case INTERNALORDER.GET_INTERNALORDER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.GET_INTERNALORDER_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        internalOrderObj: action.payload.data,
        loading: false,
        error: null,
      };
    case INTERNALORDER.GET_INTERNALORDER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
