import { Modal, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { downloadCSV } from "../Utility/CSV";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import tabClick from "../Utility/TabClick";
import { useDispatch, useSelector } from "react-redux";
import { usersGet } from "../../redux/actions/Users/users.actions";
import { updateUserKycStatus, updateUserStatus } from "../../services/users.service";
import {  getAllOrders } from "../../services/order.service";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import CustomerDetail from "./CustomerDetail";
import { EditModal } from "../Utility/Modal";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { rolesObj } from "../../utils/roles";
import { generalModelStatuses } from "../Utility/constants";
import { Link, useParams } from "react-router-dom";
import { getUserWalletApi, settleDeliveryAmountApi } from "../../services/wallet.service";
function Settlement() {


  const params = useParams()

 const [userId, setUserId] = useState("");
 const [ordersArr, setOrdersArr] = useState([]);
 const [displayOrderArr, setDisplayOrderArr] = useState([]);
 const [rejectOrderArr, setRejectOrderArr] = useState([]);
 const [deliveyObj, setDeliveyObj] = useState("");

 const [useWalletAmount, setuseWalletAmount] = useState(0);
 const [amount, setAmount] = useState(0);
 const [transactionArr, setTransactionArr] = useState([]);
 const [displytransactionArr, setdisplyTransactionArr] = useState([]);
const [totalAmount, setTotalAmount] = useState(0);
const [transferAmount, settransferAmount] = useState(0);
 const [tablist, settablist] = useState([
      {
        tabName: "All Orders",
        name:"all",
        active: true,
      },
      {
        tabName: "Completed Orders",
        name:"complete",
        active: false,
      },
      {
        tabName: "Rejected Orders",
        name:"reject",
        active: false,
      },
    ])


  useEffect(() => {
    if(params?.id){
      setUserId(params?.id);
      handleUserWallet(params?.id);
    }
  }, [params])

  useEffect(() => {
      let activeTab = tablist.find((el) => el.active == true);
      if(activeTab && ordersArr){
        if(activeTab && activeTab.name){
          if(activeTab.name == 'all'){
            setDisplayOrderArr([...ordersArr,...rejectOrderArr])
          }
          if(activeTab.name == 'complete'){
            setDisplayOrderArr(ordersArr)

          }
          if(activeTab.name == 'reject'){
            setDisplayOrderArr(rejectOrderArr)
          }
        }
      }
  }, [tablist])



  useEffect(() => {
    if(transactionArr && transactionArr?.length > 0){
        let trasnfer = transactionArr.filter(el => el.transactionType == 'Debit').reduce(function (previousValue, currentValue) {
            return previousValue + currentValue.amount;
          }, 0);
          let carttotal = transactionArr.filter(el => el.transactionType == 'Credit').reduce(function (previousValue, currentValue) {
            return previousValue + currentValue.amount;
          }, 0);
          setTotalAmount(carttotal)
          settransferAmount(trasnfer)
    }

  }, [transactionArr]);

  const handleSubmit = async () => {
    try {
      if (amount == 0) {
        toastError("Please Enter Amount")
        return
      }
       if(amount > useWalletAmount){
        toastError("Amount must be less than peding amount")
      }
      let obj = {
        amount
      }

    let {data:res} = await settleDeliveryAmountApi(userId,obj);
    if(res.message){
      toastSuccess(res.message)
      handleUserWallet(userId);
    }
    }
    catch (err) {
      toastError(err)
    }
  }
const handleUserWallet = async (userId) => {
    try {
        let { data: res } = await getUserWalletApi(userId,'');
        if (res.data) {
            console.log(res.data, "transactionArrtransactionArrtransactionArr")
            setuseWalletAmount(res.data?.deliveryAmount)
            if (res.data.transactionHistoryArr && res.data.transactionHistoryArr?.length >= 0) {
                setTransactionArr(res.data.transactionHistoryArr)
                setdisplyTransactionArr(res.data.transactionHistoryArr)
            }
        }
    } catch (error) {
        toastError(error);
    }
}



  return (
    <main>
      <section className="product-category">
        <div className="container-fluid">
          <div className="row">

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Total Amount</h5>
                    <h4 className="text-dark mb-0">₹ {totalAmount}</h4>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Pending Amount</h5>
                    <h4 className="text-dark mb-0">₹ {useWalletAmount}</h4>
                  </DashboardBox>
                </div>

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <h5 className="blue-1">Transfer Amount</h5>
                    <h4 className="text-dark mb-0">₹ {transferAmount}</h4>
                  </DashboardBox>
                </div>
          </div>
        </div>
 
        <div className="container-fluid p-0">
        
          <DashboardTable>
              <h5 className="blue-1 m-0"> Settlement</h5>
              <div className="d-flex align-items-center gap-3">
                <div className="form row">
                  <div className="col-12 col-md-12 mb-3">
                    <label>
                      Amount <span className="red">*</span>
                    </label>
                    <input
                      name="first_name"
                      className="form-control"
                      type="text"
                      required=""
                      onChange={(e) => setAmount(e.target.value)} value={amount}
                    />
                  </div>
                  <div className="col-12 text-center">
                  <button type="button" class="btn btn-1 bg-black text-white" onClick={()=>{handleSubmit()}}><i class="fa-solid fa-check me-2"></i>Settle</button>
            
            </div>
                
              </div>
              </div>
  
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default Settlement;
